//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AlertBox',
  props: {
    message: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
      // validator: (value) =>
      //   ["info", "error", "success", "warning"].includes(value.toLowerCase()),
    },
    display: {
      required: true,
      type: Boolean,
    },
  },
};
