import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _395b491c = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _717f5c5e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7fa952c9 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _562e8d6d = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _66c72da2 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _26e801a3 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _a55acbbc = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _01c65e2f = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _0a8f43ed = () => interopDefault(import('../pages/email-confirmation/index.vue' /* webpackChunkName: "pages/email-confirmation/index" */))
const _03c7a2b6 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _5cb15a38 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6be13204 = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _6df8b8fe = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _5d22fa76 = () => interopDefault(import('../pages/refund-policy/index.vue' /* webpackChunkName: "pages/refund-policy/index" */))
const _754045bf = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _7fb38a2c = () => interopDefault(import('../pages/terms-of-service/index.vue' /* webpackChunkName: "pages/terms-of-service/index" */))
const _14f12fcb = () => interopDefault(import('../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _23281053 = () => interopDefault(import('../pages/affiliate/join/index.vue' /* webpackChunkName: "pages/affiliate/join/index" */))
const _ce2184a0 = () => interopDefault(import('../pages/apps/expression-base/index.vue' /* webpackChunkName: "pages/apps/expression-base/index" */))
const _4040934c = () => interopDefault(import('../pages/email-verification/check/index.vue' /* webpackChunkName: "pages/email-verification/check/index" */))
const _0744963d = () => interopDefault(import('../pages/password-reset/check/index.vue' /* webpackChunkName: "pages/password-reset/check/index" */))
const _0bcdea83 = () => interopDefault(import('../pages/transactions/cancel/index.vue' /* webpackChunkName: "pages/transactions/cancel/index" */))
const _7d91c7b0 = () => interopDefault(import('../pages/transactions/success/index.vue' /* webpackChunkName: "pages/transactions/success/index" */))
const _4c40f736 = () => interopDefault(import('../pages/user/settings/index.vue' /* webpackChunkName: "pages/user/settings/index" */))
const _241306b2 = () => interopDefault(import('../pages/apps/expression-base/pricing/index.vue' /* webpackChunkName: "pages/apps/expression-base/pricing/index" */))
const _ac555e90 = () => interopDefault(import('../pages/blog/posts/_id/index.vue' /* webpackChunkName: "pages/blog/posts/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _395b491c,
    name: "affiliate___en"
  }, {
    path: "/ar",
    component: _717f5c5e,
    name: "index___ar"
  }, {
    path: "/blog",
    component: _7fa952c9,
    name: "blog___en"
  }, {
    path: "/checkout",
    component: _562e8d6d,
    name: "checkout___en"
  }, {
    path: "/contact",
    component: _66c72da2,
    name: "contact___en"
  }, {
    path: "/dashboard",
    component: _26e801a3,
    name: "dashboard___en"
  }, {
    path: "/docs",
    component: _a55acbbc,
    name: "docs___en"
  }, {
    path: "/download",
    component: _01c65e2f,
    name: "download___en"
  }, {
    path: "/email-confirmation",
    component: _0a8f43ed,
    name: "email-confirmation___en"
  }, {
    path: "/faq",
    component: _03c7a2b6,
    name: "faq___en"
  }, {
    path: "/fr",
    component: _717f5c5e,
    name: "index___fr"
  }, {
    path: "/login",
    component: _5cb15a38,
    name: "login___en"
  }, {
    path: "/password-reset",
    component: _6be13204,
    name: "password-reset___en"
  }, {
    path: "/privacy-policy",
    component: _6df8b8fe,
    name: "privacy-policy___en"
  }, {
    path: "/refund-policy",
    component: _5d22fa76,
    name: "refund-policy___en"
  }, {
    path: "/signup",
    component: _754045bf,
    name: "signup___en"
  }, {
    path: "/terms-of-service",
    component: _7fb38a2c,
    name: "terms-of-service___en"
  }, {
    path: "/thank-you",
    component: _14f12fcb,
    name: "thank-you___en"
  }, {
    path: "/affiliate/join",
    component: _23281053,
    name: "affiliate-join___en"
  }, {
    path: "/apps/expression-base",
    component: _ce2184a0,
    name: "apps-expression-base___en"
  }, {
    path: "/ar/affiliate",
    component: _395b491c,
    name: "affiliate___ar"
  }, {
    path: "/ar/blog",
    component: _7fa952c9,
    name: "blog___ar"
  }, {
    path: "/ar/checkout",
    component: _562e8d6d,
    name: "checkout___ar"
  }, {
    path: "/ar/contact",
    component: _66c72da2,
    name: "contact___ar"
  }, {
    path: "/ar/dashboard",
    component: _26e801a3,
    name: "dashboard___ar"
  }, {
    path: "/ar/docs",
    component: _a55acbbc,
    name: "docs___ar"
  }, {
    path: "/ar/download",
    component: _01c65e2f,
    name: "download___ar"
  }, {
    path: "/ar/email-confirmation",
    component: _0a8f43ed,
    name: "email-confirmation___ar"
  }, {
    path: "/ar/faq",
    component: _03c7a2b6,
    name: "faq___ar"
  }, {
    path: "/ar/login",
    component: _5cb15a38,
    name: "login___ar"
  }, {
    path: "/ar/password-reset",
    component: _6be13204,
    name: "password-reset___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _6df8b8fe,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/refund-policy",
    component: _5d22fa76,
    name: "refund-policy___ar"
  }, {
    path: "/ar/signup",
    component: _754045bf,
    name: "signup___ar"
  }, {
    path: "/ar/terms-of-service",
    component: _7fb38a2c,
    name: "terms-of-service___ar"
  }, {
    path: "/ar/thank-you",
    component: _14f12fcb,
    name: "thank-you___ar"
  }, {
    path: "/email-verification/check",
    component: _4040934c,
    name: "email-verification-check___en"
  }, {
    path: "/fr/affiliate",
    component: _395b491c,
    name: "affiliate___fr"
  }, {
    path: "/fr/blog",
    component: _7fa952c9,
    name: "blog___fr"
  }, {
    path: "/fr/checkout",
    component: _562e8d6d,
    name: "checkout___fr"
  }, {
    path: "/fr/contact",
    component: _66c72da2,
    name: "contact___fr"
  }, {
    path: "/fr/dashboard",
    component: _26e801a3,
    name: "dashboard___fr"
  }, {
    path: "/fr/docs",
    component: _a55acbbc,
    name: "docs___fr"
  }, {
    path: "/fr/download",
    component: _01c65e2f,
    name: "download___fr"
  }, {
    path: "/fr/email-confirmation",
    component: _0a8f43ed,
    name: "email-confirmation___fr"
  }, {
    path: "/fr/faq",
    component: _03c7a2b6,
    name: "faq___fr"
  }, {
    path: "/fr/login",
    component: _5cb15a38,
    name: "login___fr"
  }, {
    path: "/fr/password-reset",
    component: _6be13204,
    name: "password-reset___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _6df8b8fe,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/refund-policy",
    component: _5d22fa76,
    name: "refund-policy___fr"
  }, {
    path: "/fr/signup",
    component: _754045bf,
    name: "signup___fr"
  }, {
    path: "/fr/terms-of-service",
    component: _7fb38a2c,
    name: "terms-of-service___fr"
  }, {
    path: "/fr/thank-you",
    component: _14f12fcb,
    name: "thank-you___fr"
  }, {
    path: "/password-reset/check",
    component: _0744963d,
    name: "password-reset-check___en"
  }, {
    path: "/transactions/cancel",
    component: _0bcdea83,
    name: "transactions-cancel___en"
  }, {
    path: "/transactions/success",
    component: _7d91c7b0,
    name: "transactions-success___en"
  }, {
    path: "/user/settings",
    component: _4c40f736,
    name: "user-settings___en"
  }, {
    path: "/apps/expression-base/pricing",
    component: _241306b2,
    name: "apps-expression-base-pricing___en"
  }, {
    path: "/ar/affiliate/join",
    component: _23281053,
    name: "affiliate-join___ar"
  }, {
    path: "/ar/apps/expression-base",
    component: _ce2184a0,
    name: "apps-expression-base___ar"
  }, {
    path: "/ar/email-verification/check",
    component: _4040934c,
    name: "email-verification-check___ar"
  }, {
    path: "/ar/password-reset/check",
    component: _0744963d,
    name: "password-reset-check___ar"
  }, {
    path: "/ar/transactions/cancel",
    component: _0bcdea83,
    name: "transactions-cancel___ar"
  }, {
    path: "/ar/transactions/success",
    component: _7d91c7b0,
    name: "transactions-success___ar"
  }, {
    path: "/ar/user/settings",
    component: _4c40f736,
    name: "user-settings___ar"
  }, {
    path: "/fr/affiliate/join",
    component: _23281053,
    name: "affiliate-join___fr"
  }, {
    path: "/fr/apps/expression-base",
    component: _ce2184a0,
    name: "apps-expression-base___fr"
  }, {
    path: "/fr/email-verification/check",
    component: _4040934c,
    name: "email-verification-check___fr"
  }, {
    path: "/fr/password-reset/check",
    component: _0744963d,
    name: "password-reset-check___fr"
  }, {
    path: "/fr/transactions/cancel",
    component: _0bcdea83,
    name: "transactions-cancel___fr"
  }, {
    path: "/fr/transactions/success",
    component: _7d91c7b0,
    name: "transactions-success___fr"
  }, {
    path: "/fr/user/settings",
    component: _4c40f736,
    name: "user-settings___fr"
  }, {
    path: "/ar/apps/expression-base/pricing",
    component: _241306b2,
    name: "apps-expression-base-pricing___ar"
  }, {
    path: "/fr/apps/expression-base/pricing",
    component: _241306b2,
    name: "apps-expression-base-pricing___fr"
  }, {
    path: "/ar/blog/posts/:id",
    component: _ac555e90,
    name: "blog-posts-id___ar"
  }, {
    path: "/fr/blog/posts/:id",
    component: _ac555e90,
    name: "blog-posts-id___fr"
  }, {
    path: "/ar/blog/posts/:id/:slug",
    component: _ac555e90,
    name: "blog-post___ar"
  }, {
    path: "/fr/blog/posts/:id/:slug",
    component: _ac555e90,
    name: "blog-post___fr"
  }, {
    path: "/blog/posts/:id",
    component: _ac555e90,
    name: "blog-posts-id___en"
  }, {
    path: "/blog/posts/:id/:slug",
    component: _ac555e90,
    name: "blog-post___en"
  }, {
    path: "/",
    component: _717f5c5e,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
