//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Snackbar from '~/components/Snackbar.vue';
import AlertBox from '@/components/UI/AlertBox';
import StickyHeaderCardVue from '~/components/UI/Card/StickyHeaderCard.vue';
import AnnouncementBanner from '../components/UI/AnnouncementBanner.vue';

export default {
  name: 'Default',
  // middleware: ["check-auth"],
  components: { Snackbar, AlertBox, StickyHeaderCardVue, AnnouncementBanner },
  data() {
    return {
      expired: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      cookie: true,
      drawer: false,
      showMessage: false,
      messageText: '',
      messageType: 'warning',
      resendLoading: false,
      group: null,
      languages: [
        { title: 'English', img: '/img/flag_en.svg', iso: 'en' },
        { title: 'Français', img: '/img/flag_fr.svg', iso: 'fr' },
        { title: 'العربية', img: '/img/flag_ar.svg', iso: 'ar' },
      ],
      drawerLinks: [
        { title: 'pages.home', url: '/', icon: 'mdi-home' },
        {
          title: 'pages.eb',
          url: '/apps/expression-base/',
          icon: 'mdi-crown-outline',
        },
        // { title: "Apps", url: "/apps" , icon: "mdi-"},
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        {
          title: 'pages.docs',
          url: '/docs',
          icon: 'mdi-text-box-check-outline',
        },
        { title: 'pages.contact', url: '/contact', icon: 'mdi-email-outline' },
        { title: 'buttons.signup', url: '/signup', icon: 'mdi-account-plus' },
        { title: 'buttons.login', url: '/login', icon: 'mdi-login' },
      ],
      drawerLoggedInLinks: [
        { title: 'pages.home', url: '/', icon: 'mdi-home' },
        {
          title: 'pages.eb',
          url: '/apps/expression-base/',
          icon: 'mdi-crown-outline',
        },
        // { title: "Apps", url: "/apps" , icon: "mdi-"},
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        { title: 'pages.contact', url: '/contact', icon: 'mdi-email-outline' },
        { title: 'pages.settings', url: '/user/settings', icon: 'mdi-cog' },
        { title: 'buttons.logout', url: '/', icon: 'mdi-logout' },
      ],
      headerLinks: [
        { title: 'pages.home', url: 'index', badge: false },
        {
          title: 'pages.eb',
          url: '/apps/expression-base/',
          badge: true,
        },
        // { title: "Apps", url: "/apps" },
        // { title: "FAQ", url: "/faq" },
        // { title: "Blog", url: "/blog" },
        {
          title: 'pages.contact',
          url: `/contact`,
          badge: false,
        },
      ],
      footerLinks: [
        // { title: "pages.aff", url: "/affiliate" },
        // { title: "pages.dev", url: "/developers" },
        { title: 'pages.lic', url: '/terms-of-service' },
        { title: 'pages.refund', url: '/refund-policy' },
        { title: 'pages.privacy', url: '/privacy-policy' },
        { title: 'pages.faq', url: '/faq' },
        { title: 'pages.contact', url: '/contact' },
      ],
      footerBtn: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/MotionRoutes/',
        },
        {
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UCbc_cGZ8Jryu3Hs-4rnNgKw',
        },
        {
          icon: 'mdi-twitter',
          link: 'https://www.twitter.com/MotionRoutes',
        },
        {
          icon: 'mdi-instagram',
          link: 'https://www.instagram.com/motionroutes',
        },
      ],
      resources: [
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        {
          title: 'pages.docs',
          url: '/docs',
          icon: 'mdi-text-box-check-outline',
        },
      ],
    };
  },

  mounted() {
    this.$vuetify.rtl =
      this.$i18n.locale == 'ar'
        ? (this.$vuetify.rtl = true)
        : (this.$vuetify.rtl = false);
    this.cookie =
      this.$auth.$storage.getUniversal('isCookieAccepted') === undefined
        ? true
        : false;
    this.countDown();
  },
  methods: {
    countDown() {
      // Update the count down every 1 second
      let timer = setInterval(() => {
        var now = new Date().getTime();
        const end = new Date(this.$config.mrSaleEndDate).getTime();
        var distance = end - now;

        if (distance < 0) {
          this.expired = true;
          clearInterval(timer);
          return;
        } else {
          this.expired = false;
        }

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
    acceptCookie() {
      this.$auth.$storage.setUniversal('isCookieAccepted', true);
      this.cookie = false;
    },
    async logout() {
      await this.$auth.logout();
      await this.$notifier.showMessage({
        content: this.$t('bye') + '! 👋',
        color: 'customOne',
        timeout: 3000,
      });
      this.$sentry.configureScope((scope) => scope.setUser(null));
    },
    async sendVerification(email) {
      this.resendLoading = true;
      const [result, error] = await this.$store.dispatch(
        'user/requestEmailVerificationToken'
      );
      if (error) {
        this.resendLoading = false;
        this.$notifier.showMessage({
          content: error.message,
          color: 'error',
          timeout: 3000,
        });
      } else {
        this.resendLoading = false;
        this.$notifier.showMessage({
          content: result,
          color: 'customOne',
          timeout: 3000,
        });
      }
    },
    //TODO: fix logout inside mobile
    setLocale(locale) {
      // TODO: set locale to store
      this.$auth.$storage.setUniversal('lang', locale);
      this.$i18n.locale = locale;
      this.$i18n.locale == 'ar'
        ? (this.$vuetify.rtl = true)
        : (this.$vuetify.rtl = false);
      // this.switchLocalePath(locale);
      // this.$router.push({ params: { lang: locale } });
    },
  },
  computed: {
    ...mapState({
      discount: (state) => state.global.discount,
      email: (state) => state.user.email,
      profileImageURL: (state) => state.auth.user.profileImageURL,
      showEmailNotVerified: (state) => {
        if (state.auth.loggedIn && state.auth.user.status == 'non_verified') {
          return true;
        } else return false;
      },
      initials: (state) => {
        if (state.auth.user.fullName) {
          var initials =
            decodeURI(state.auth.user.fullName).match(/\b\w/g) || [];
          initials = (
            (initials.shift() || '') + (initials.pop() || '')
          ).toUpperCase();
          return initials;
        }
      },
    }),

    profileImageFullURL() {
      return this.$config.mrBaseUrl + this.profileImageURL;
    },
    height() {
      const { xs, sm, md, lg } = this.$vuetify.breakpoint;
      // eslint-disable-next-line no-nested-ternary
      return xs ? 80 : sm ? 56 : md ? 56 : lg ? 56 : 56;
    },
  },
};
//TODO: check the auth.lang and make a persistent language with i18n
