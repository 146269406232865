export default async function (
  context
  //     {
  //   store,
  //   query: { token, id },
  //   $ga,
  //   $notifier,
  //   $auth,
  //     app: { router, localeRoute },
  // }
) {
  //   if (token && id) {
  //     $ga.event(
  //       "Account",
  //       "New Account Email Verification",
  //       "User New Account Email Verification Button clicked",
  //       1
  //     );
  //     const [result, error] = await store.dispatch(
  //       "user/verifyEmailVerificationToken",
  //       {
  //         uuid: id,
  //         token: token
  //       }
  //     );
  //     if (error) {
  //       if (error.status == 403) {
  //         if (query.a === "eb1") {
  //           if (query.p == 0)
  //             router.push(localeRoute("/apps/expression-base/download/?pid=0"));
  //           if (query.p == 1)
  //             router.push(localeRoute("/apps/expression-base/checkout/pro"));
  //           if (query.p == 2)
  //             router.push(localeRoute("/apps/expression-base/checkout/plus"));
  //         } else {
  //           router.push(localeRoute("/"));
  //         }
  //       } else {
  //         $notifier.showMessage({
  //           content: error.message,
  //           color: "error",
  //           timeout: 5000
  //         });
  //       }
  //       $ga.event(
  //         "Account",
  //         "New Account Email Verification Fail",
  //         "User New Account Email Verification Failed in Email Verification Page",
  //         1
  //       );
  //     } else {
  //       $ga.event(
  //         "Account",
  //         "New Account Email Verification Success",
  //         "User New Account Email Verification  Succeed in Email Verification Page",
  //         1
  //       );
  //       $notifier.showMessage({
  //         content: result,
  //         color: "success",
  //         timeout: 5000
  //       });
  //       const updatedUser = { ...$auth.user };
  //       updatedUser.status = "verified";
  //       $auth.setUser(updatedUser);
  //     }
  //   }
}
