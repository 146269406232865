/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
export const state = () => ({
  selectedPlan: null,
  selectedProduct: null,
  selectedProductVideoUrl: null,
  selectedProductName: null,
  selectedProductSlug: null,
  emailToken: null,
  emailId: null,
  isCookieAccepted: false,
  referral: null,
  discount: null,
});

export const mutations = {
  SET_COOKIE(state, response) {
    state.isCookieAccepted = response;
  },
  SET_PLAN(state, plan) {
    state.selectedPlan = plan;
  },
  SET_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_PRODUCT_VIDEO_URL(state, videoUrl) {
    state.selectedProductVideoUrl = videoUrl;
  },
  SET_PRODUCT_VIDEO_NAME(state, productName) {
    state.selectedProductName = productName;
  },
  SET_PRODUCT_VIDEO_SLUG(state, productSlug) {
    state.selectedProductSlug = productSlug;
  },
  SET_EMAIL_TOKEN(state, emailToken) {
    state.emailToken = emailToken;
  },
  SET_EMAIL_ID(state, emailId) {
    state.emailId = emailId;
  },
  SET_DISCOUNT(state, discount) {
    state.discount = discount;
  },
};

export const actions = {
  setProductAndPlan({ commit }, { plan, product }) {
    commit('SET_PLAN', plan);
    commit('SET_PRODUCT', product);
    return [true, undefined];
  },
  setTokenAndId({ commit }, { id, token }) {
    commit('SET_EMAIL_ID', id);
    commit('SET_EMAIL_TOKEN', token);
    return [true, undefined];
  },
  setDiscount({ commit }, discount) {
    commit('SET_DISCOUNT', discount);
    return [true, undefined];
  },
  setSubscription({ commit }, subscription) {
    commit('SET_SUBSCRIPTION', subscription);
  },
  setProductDetails({ commit }, { videoUrl, slug, name }) {
    commit('SET_PRODUCT_VIDEO_NAME', name);
    commit('SET_PRODUCT_VIDEO_SLUG', slug);
    commit('SET_PRODUCT_VIDEO_URL', videoUrl);
  },
  resetStore({ commit }, { videoUrl, slug, name, id, token, plan, product }) {
    commit('SET_PRODUCT_VIDEO_NAME', name);
    commit('SET_PRODUCT_VIDEO_SLUG', slug);
    commit('SET_PRODUCT_VIDEO_URL', videoUrl);
    commit('SET_EMAIL_ID', id);
    commit('SET_EMAIL_TOKEN', token);
    commit('SET_PLAN', plan);
    commit('SET_PRODUCT', product);
  },
  async affiliateCountUp({ commit }, { userName }) {
    return this.$fn.callMainAPI(
      this,
      'incrementAffiliateClicks',
      {},
      { params: userName, onlyData: true }
    );
  },
  async getAffiliateDiscount({ commit }, { userName }) {
    return this.$fn.callMainAPI(
      this,
      'affiliateDiscount',
      {},
      { params: userName, onlyData: true }
    );
  },
};
