export default (context, inject) => {
  const prg = {
    eb1: [
      {
        uuid: context.$config.mrEBFreeUuid,
        type: 'free',
        name: 'Expression Base Free',
        urlSlug: '/apps/expression-base/',
        promoVideoUrl:
          'https://www.youtube.com/embed/bTk6eVVymuc?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
        videoUrl:
          'https://www.youtube.com/embed/3lWDgzC4tLU?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
      },
      {
        uuid: context.$config.mrEBPremiumUuid,
        type: 'paid',
        name: 'Expression Base Premium',
        urlSlug: '/apps/expression-base/',
        promoVideoUrl:
          'https://www.youtube.com/embed/bTk6eVVymuc?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
        videoUrl:
          'https://www.youtube.com/embed/3lWDgzC4tLU?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
      },
    ],
  };
  inject('prg', prg);
};
