export const state = () => ({
  list: [],
  userCount: 0,
  userExpressionCount: 0,
  publicExpressionCount: 0,
});

export const mutations = {
  SET_POSTS(state, list) {
    state.list = Array.isArray(list) ? list : [list];
  },
  SET_SATS(state, count) {
    state.userCount = count[0];
    state.userExpressionCount = count[1];
    state.publicExpressionCount = count[2];
  },
};

export const actions = {
  async load({ commit }) {
    try {
      const [data, dataError] = await this.$helpers.handle(
        this.$mrApi.$get('/blog')
      );
      if (dataError) throw new Error(dataError.response.data);
      else {
        commit('SET_POSTS', data.body.blogPost);
        /**
         * TODO: to be fixed later on when we get number of users and expressions from db
         */
        commit('SET_SATS', [
          data.body.stats.body.nbUsers,
          data.body.stats.body.nbExpressions,
          data.body.stats.body.nbPublic,
        ]);
      }
    } catch (error) {}
  },
  async loadSinglePost({ commit }, id) {
    try {
      const [data, dataError] = await this.$helpers.handle(
        this.$mrApi.$get('/blog/' + id)
      );
      if (dataError) throw new Error(dataError.response.data);
      else {
        commit('SET_POSTS', data.body.blogPost);
      }
    } catch (error) {}
  },
};
