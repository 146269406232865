const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['getDiscount'] = require('../middleware/getDiscount.js')
middleware['getDiscount'] = middleware['getDiscount'].default || middleware['getDiscount']

middleware['isAffiliated'] = require('../middleware/isAffiliated.js')
middleware['isAffiliated'] = middleware['isAffiliated'].default || middleware['isAffiliated']

middleware['isNotAuthAndReinjectStore'] = require('../middleware/isNotAuthAndReinjectStore.js')
middleware['isNotAuthAndReinjectStore'] = middleware['isNotAuthAndReinjectStore'].default || middleware['isNotAuthAndReinjectStore']

middleware['isPasswordTokenAndIdExist'] = require('../middleware/isPasswordTokenAndIdExist.js')
middleware['isPasswordTokenAndIdExist'] = middleware['isPasswordTokenAndIdExist'].default || middleware['isPasswordTokenAndIdExist']

middleware['isPlanSelected'] = require('../middleware/isPlanSelected.js')
middleware['isPlanSelected'] = middleware['isPlanSelected'].default || middleware['isPlanSelected']

middleware['isProductUUIDExist'] = require('../middleware/isProductUUIDExist.js')
middleware['isProductUUIDExist'] = middleware['isProductUUIDExist'].default || middleware['isProductUUIDExist']

middleware['load-data'] = require('../middleware/load-data.js')
middleware['load-data'] = middleware['load-data'].default || middleware['load-data']

middleware['not-authenticated'] = require('../middleware/not-authenticated.js')
middleware['not-authenticated'] = middleware['not-authenticated'].default || middleware['not-authenticated']

middleware['recaptchaScan'] = require('../middleware/recaptchaScan.js')
middleware['recaptchaScan'] = middleware['recaptchaScan'].default || middleware['recaptchaScan']

middleware['redirect-to-docs'] = require('../middleware/redirect-to-docs.js')
middleware['redirect-to-docs'] = middleware['redirect-to-docs'].default || middleware['redirect-to-docs']

middleware['redirectToBlogPostSlug'] = require('../middleware/redirectToBlogPostSlug.js')
middleware['redirectToBlogPostSlug'] = middleware['redirectToBlogPostSlug'].default || middleware['redirectToBlogPostSlug']

middleware['verifyEmailBeforeDownload'] = require('../middleware/verifyEmailBeforeDownload.js')
middleware['verifyEmailBeforeDownload'] = middleware['verifyEmailBeforeDownload'].default || middleware['verifyEmailBeforeDownload']

middleware['verifyEmailConfirmationToken'] = require('../middleware/verifyEmailConfirmationToken.js')
middleware['verifyEmailConfirmationToken'] = middleware['verifyEmailConfirmationToken'].default || middleware['verifyEmailConfirmationToken']

export default middleware
