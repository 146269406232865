export default {
  home: {
    route: '/',
  },
  login: {
    route: '/login',
  },
  signup: {
    route: '/signup',
  },
  emailVerification: {
    route: '/email-verification/check',
  },
  checkout: {
    route: '/checkout',
  },
  download: {
    route: '/download',
  },
  dashboard: {
    route: '/dashboard',
  },
  emailActionNeeded: {
    route: '/email-confirmation',
  },
  passwordReset: {
    route: '/password-reset',
  },
  thankyou: {
    route: '/thank-you',
  },
  expressionBasePricing: {
    route: '/apps/expression-base/pricing',
  },
  expressionBase: {
    route: '/apps/expression-base',
  },
};
