// eslint-disable-next-line func-names
export default function ({ route, store, redirect }) {
  const {
    selectedPlan,
    selectedProduct,
    selectedProductVideoUrl,
    selectedProductName,
    selectedProductSlug,
    emailToken,
    emailId,
  } = store.state.global;

  if (!store.state.auth.loggedIn) {
    store.dispatch('global/resetStore', {
      plan: selectedPlan,
      product: selectedProduct,
      videoUrl: selectedProductVideoUrl,
      name: selectedProductName,
      slug: selectedProductSlug,
      token: emailToken,
      id: emailId,
    });
    return redirect(`/login/?inc=${route.path}`);
  }
  return true;
}
