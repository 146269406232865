export default function({ $axios, $config, app }, inject) {
  const mrApi = $axios.create({
    baseURL: $config.mrApiBaseUrl,
    withCredentials: false
  });

  // Inject 'Authorization' header
  [$axios, mrApi].forEach(api => {
    api.onRequest(config => {
      if (app.$auth.loggedIn) {
        config.headers.common["Authorization"] = app.$auth.strategy.token.get();
      }
    });
  });

  inject("mrApi", mrApi);

  $axios.onRequest(config => {
    if (config.url.split("/")[1] === "api") {
      config.headers.common["Accept-Language"] = app.i18n.locale;
    }
  });
}
