//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    message: { required: true, type: String, default: null },
  },
};
