export default async function ({ route, store, app, redirect }) {
  const query = route.query;

  if (query && Object.keys(query).length > 0 && query.ref) {
    const ref = query.ref;
    const cp = query.cp;
    const [result, error] = await store.dispatch('global/affiliateCountUp', {
      userName: ref,
    });
    if (result)
      app.$cookiz.set(
        '__MOTIONROUTES_REFERRAL_ID',
        JSON.stringify({ ref, cp, timestamp: +new Date() }),
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 30 * 1,
          // maxAge: 60 * 5,
          // expires: new Date(Date.now() + 86400 * 1000).toUTCString(),
        }
      );
    redirect(route.path);
  }
}
