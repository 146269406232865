/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */

const fnStore = {
  name: 'Store Helper Functions',
  log(msg) {
    if (process.env.NODE_ENV === 'development')
      // eslint-disable-next-line no-console
      console.log(`DEBUG: =>`, msg);
  },
  isLoggedIn(context) {
    return context.$auth.loggedIn;
  },
  getUserProp(context, property, fromStore = false) {
    //! Move this to getProp
    if (fromStore) return context.$store.state.auth.user[property];
    return context.$auth.user[property];
  },
  getProp(context, options = {}) {
    if (options.store && options.store === 'global')
      return context.$store.state.global[options.property];
    if (options.store && options.store === 'auth.user')
      return context.$store.state.auth.user[options.property];
    throw new Error(
      `${options.property} is not defined in the store ${options.store}`
    );
  },
  updateUserStatus(context) {
    const updatedUser = { ...context.$auth.user };
    updatedUser.status = 'verified';
    context.$auth.setUser(updatedUser);
  },
  updateUserProp(context, options) {
    const updatedUser = { ...context.$auth.user };
    updatedUser[options.property] = options.value;
    context.$auth.setUser(updatedUser);
    this.log(
      `updateUserProp || property: ${options.property} | value: ${options.value}`
    );
  },
};

export default (context, inject) => {
  inject('fnStore', fnStore);
};
