const resendConfirmation = async (context) => {
  await context.$fn.exec(context, {
    ga: 'newEmailVerificationRequestRegenerated',
    store: {
      action: 'user/requestNewEmailVerificationToken',
      data: {
        email: context.email,
      },
    },
    alertWith: 'box',
    loader: 'loader',
  });
};

const storeProductAndPlanFromParamsToStore = (context) => {
  if (
    context.$fn.getURLParamValue('p') !== undefined ||
    context.$fn.getURLParamValue('a') !== undefined
  ) {
    context.$fnCore.storeDispatch(context, 'global/setProductAndPlan', {
      plan: context.$fn.getURLParamValue('p'),
      product: context.$fn.getURLParamValue('a'),
    });
  }
};
const storeEmailTokenAndIdFromParamsToStore = (context) => {
  context.$fnCore.storeDispatch(context, 'global/setTokenAndId', {
    id: context.$fn.getURLParamValue('id'),
    token: context.$fn.getURLParamValue('token'),
  });
};

const getPlanTypeFromParamsOrStore = (context) => {
  try {
    const type = context.$fn.getPlanTypeByProduct(
      context,
      context.$fn.getURLParamValue('a') ||
        context.$fnStore.getProp(context, {
          store: 'global',
          property: 'selectedProduct',
        }),
      context.$fn.getURLParamValue('p') ||
        context.$fnStore.getProp(context, {
          store: 'global',
          property: 'selectedPlan',
        })
    );
    if (!type) throw new Error('No plan found for this product!');
    return type;
  } catch (error) {
    // context.$fn.toggleAlert(context, 'toast', { message: error.message });
    return null;
  }
};

const subscribeToFreeProduct = async (context) => {
  await context.$fn.exec(context, {
    ga: 'freeSubscriptionRequested',
    store: {
      action: 'user/subscribeToProduct',
      data: {
        uuid:
          context.$fn.getURLParamValue('p') ||
          context.$fnStore.getProp(context, {
            store: 'global',
            property: 'selectedPlan',
          }),
      },
    },
    alertWith: 'box',
    alertSuccessSkip: true,
    onResolve(data) {
      return data.body.subscriber;
      // context.$fnCore.storeDispatch(
      //   context,
      //   'user/setSubscription',
      //   data.body.subscriber
      // );
    },
  });
};

const redirectToCheckout = (context) => {
  context.$fn.redirect(context, 'checkout');
};

const onMountedVerifyEmail = async (context) => {
  if (
    context.$fn.verifyURLParams(context, 'token') ||
    context.$fnStore.getProp(context, {
      store: 'global',
      property: 'emailToken',
    })
  ) {
    await context.$fn.exec(context, {
      ga: 'newEmailVerificationRequested',
      store: {
        action: 'user/verifyEmailVerificationToken',
        data: {
          uuid:
            context.$fn.getURLParamValue('id') ||
            context.$fnStore.getProp(context, {
              store: 'global',
              property: 'emailId',
            }),
          token:
            context.$fn.getURLParamValue('token') ||
            context.$fnStore.getProp(context, {
              store: 'global',
              property: 'emailToken',
            }),
        },
      },
      async onResolve() {
        //* email was verified successfully
        if (context.$fnStore.isLoggedIn(context)) {
          //* if the user is logged in
          context.$fn.log('the user is logged in');
          context.$fnStore.updateUserProp(context, {
            property: 'status',
            value: 'verified',
          });
          if (getPlanTypeFromParamsOrStore(context) === 'paid') {
            //* paid app signup => redirect to checkout page
            context.$fn.log('paid app signup => redirect to checkout page');
            storeProductAndPlanFromParamsToStore(context);
            redirectToCheckout(context);
          } else if (getPlanTypeFromParamsOrStore(context) === 'free') {
            //* free app signup => redirect to download page
            context.$fn.log('free app signup => redirect to download page');
            storeProductAndPlanFromParamsToStore(context);
            // subscribeToFreeProduct(context);
            context.$fn.redirect(context, 'download');
          } else if (context.$fn.getURLParamValue('redirect') === 'dashboard') {
            //* simple affiliate signup => redirect to dashboard page
            context.$fn.log('simple affiliate signup => redirect to dashboard');
            context.$fn.redirect(context, 'dashboard');
          } else {
            //* simple signup => redirect to home
            context.$fn.log('simple signup => redirect to home');
            context.$fn.redirect(context, 'home');
          }
        } else {
          //* if the user is not logged in
          context.$fn.log('the user is not logged in');
          if (getPlanTypeFromParamsOrStore(context) === 'paid') {
            //* paid app signup => redirect to checkout page
            context.$fn.log(
              'paid app signup => redirect to login with inc=checkout page'
            ); //! add additional params for the app uuid: app=uuid
            storeProductAndPlanFromParamsToStore(context);
            context.$fn.redirect(context, 'login', {
              incomingLink: 'checkout',
            });
          } else if (getPlanTypeFromParamsOrStore(context) === 'free') {
            //* free app signup => redirect to download page
            context.$fn.log(
              'free app signup => redirect to login with inc=download page'
            ); //! add additional params for the app uuid: app=uuid
            storeProductAndPlanFromParamsToStore(context);
            storeEmailTokenAndIdFromParamsToStore(context);
            context.$fn.redirect(context, 'login', {
              incomingLink: 'emailVerification',
            });
          } else {
            //* simple signup => redirect to home
            context.$fn.log('simple signup => redirect to login with inc=home');
            context.$fn.redirect(context, 'login', { incomingLink: 'home' });
          }

          //* redirect login with incomingLink
          // context.$fn.toggleAlert(context, 'toast', {
          //   message: 'Please login and try again!',
          //   type: 'error',
          // });
          // context.$fn.redirect(context, 'login', {
          //   incomingLink: 'emailVerification',
          // });
        }
      },
      async onError(error) {
        if (error.status === 403) {
          //* email was already verified
          context.$fn.log('email was already verified');
          if (context.$fnStore.isLoggedIn(context)) {
            //* if the user is logged in
            context.$fn.log('the user is logged in');
            if (getPlanTypeFromParamsOrStore(context) === 'paid') {
              //* paid app signup => redirect to checkout page
              storeProductAndPlanFromParamsToStore(context);
              redirectToCheckout(context);
              context.$fn.log('paid app signup => redirect to checkout page');
            } else if (getPlanTypeFromParamsOrStore(context) === 'free') {
              //* free app signup => redirect to download page
              context.$fn.log('free app signup => redirect to download page');
              storeProductAndPlanFromParamsToStore(context);
              // subscribeToFreeProduct(context);
              context.$fn.redirect(context, 'download');
            } else {
              //* simple signup => redirect to home
              context.$fn.log('simple signup => redirect to home');
              context.$fn.redirect(context, 'home');
            }
          } else {
            context.$fn.log('the user is not logged in');
            if (getPlanTypeFromParamsOrStore(context) === 'paid') {
              //* paid app signup => redirect to checkout page
              context.$fn.log(
                'paid app signup => redirect to login with inc=checkout page'
              ); //! add additional params for the app uuid: app=uuid
              storeProductAndPlanFromParamsToStore(context);
              context.$fn.redirect(context, 'login', {
                incomingLink: 'checkout',
              });
            } else if (getPlanTypeFromParamsOrStore(context) === 'free') {
              //* free app signup => redirect to download page
              context.$fn.log(
                'free app signup => redirect to login with inc=download page'
              ); //! add additional params for the app uuid: app=uuid
              storeProductAndPlanFromParamsToStore(context);
              storeEmailTokenAndIdFromParamsToStore(context);
              context.$fn.redirect(context, 'login', {
                incomingLink: 'emailVerification',
              });
            } else {
              //* simple signup => redirect to home
              context.$fn.log(
                'simple signup => redirect to login with inc=home'
              );
              context.$fn.redirect(context, 'login', { incomingLink: 'home' });
            }
          }
          // await subscribeToFreeProduct(context);
          // redirectToCheckout(context);
          // context.$fn.redirect(context, 'home');
        } else {
          //* email verification token expired
          context.$fn.log('email verification token expired');
          context.$fn.log('toggle the send email verification link');
          context.$fn.toggleOn(context, 'expired');
          context.$fn.toggleAlert(context, 'toast', {
            message: error.message,
            type: 'error',
          });
        }
      },
      alertWith: 'toast',
    });
  } else {
    context.$fn.toggleOn(context, 'expired');
    context.$fn.toggleAlert(context, 'toast', {
      message: context.$t('reset_password.invalid_token'),
      type: 'error',
    });
  }
};

const onLoginClick = async (context) => {
  context.$fn.exec(context, {
    ga: 'userLoginRequested',
    store: {
      action: 'user/login',
      data: {
        email: context.email,
        password: context.password,
      },
    },
    alertWith: 'box',
    alertSuccessSkip: true,
    loader: 'loader',
    onResolve() {
      context.$fn.toggleAlert(context, 'toast', {
        message: `${context.$t('hello') + context.$auth.user.fullName}! 😍🥳🥳`,
        type: 'customOne',
      });
      context.$fn.afterLoginRedirect(context);
    },
  });
};

const onChangeCheckInputValidity = async (context, type, isReversed) => {
  if (type === 'email') {
    if (
      context.email !== null &&
      context.email !== undefined &&
      context.email.length > 2 &&
      context.$v.email.email &&
      context.$v.email.required
    ) {
      await context.$fn.checkValidity(context, type, isReversed);
    }
  } else if (type === 'username') {
    if (
      context.userName !== null &&
      context.userName !== undefined &&
      context.userName.length > 2 &&
      context.$v.userName.maxLength &&
      context.$v.userName.minLength
    ) {
      await context.$fn.checkValidity(context, type);
    }
  }
};

const onCheckPasswordInputStrength = (context) => {
  context.$fn.toggleOn(context, 'showStrengthMeter');
  const color = ['red', 'customTwo', 'orange', 'yellow', 'success'];
  const strength = [0, 25, 50, 75, 100];
  if (context.password !== null && context.password !== undefined) {
    const { score } = context.$zxcvbn(context.password);
    context.strengthColor = color[score];
    context.strength = strength[score];
    if (score === 4)
      context.passwordSuccess = context.$t('signup.password_hint');
  }
};
const onCheckPasswordStrength = (context, password) => {
  context.$fn.toggleOn(context, 'showStrengthMeter');
  const color = ['red', 'customTwo', 'orange', 'yellow', 'success'];
  const strength = [0, 25, 50, 75, 100];
  if (password !== null && password !== undefined) {
    const { score } = context.$zxcvbn(password);
    context.strengthColor = color[score];
    context.strength = strength[score];
    if (score === 4)
      context.passwordSuccess = context.$t('signup.password_hint');
  }
};

const onSignupClick = async (context) => {
  const user = context.isSelectedPlan
    ? {
        fullName: context.fullName,
        email: context.email,
        userName: context.userName,
        password: context.password,
        plan: context.selectedPlan,
        app: context.selectedProduct,
      }
    : {
        fullName: context.fullName,
        email: context.email,
        userName: context.userName,
        password: context.password,
      };
  context.$fn.exec(context, {
    ga: context.isSelectedPlan
      ? 'userSignupRequested'
      : 'userAppSignupRequested',
    store: {
      action: 'user/signup',
      data: user,
    },
    formValidation: true,
    recaptchaValidation: true,
    loader: 'loader',
    alertWith: 'toast',
    // TODO:turn off sucess message
    onResolve() {
      context.$fn.redirect(context, 'emailActionNeeded');
    },
  });
};

const onAffiliateSignupClick = async (context) => {
  const affiliate =
    context.accountType === context.accountTypes[0]
      ? {
          type: 'individual',
          payload: context.individual,
        }
      : {
          type: 'business',
          payload: context.business,
        };
  context.$fn.exec(context, {
    store: {
      action: 'user/affiliateSignup',
      data: affiliate,
    },
    // formValidation: true,
    recaptchaValidation: true,
    loader: 'loader',
    alertWith: 'toast',
    // TODO:turn off sucess message
    onResolve() {
      context.$fn.redirect(context, 'emailActionNeeded');
    },
  });
};

const onContactClick = async (context) => {
  context.$fn.exec(context, {
    ga: 'userContactRequested',
    store: {
      action: 'user/sendContactMessage',
      data: {
        selectedTopic: context.selectedTopic,
        selectedApp: context.selectedApp,
        name: context.fullName,
        email: context.email,
        message: context.message,
        issue: context.issue,
      },
    },
    formValidation: true,
    recaptchaValidation: true,
    loader: 'contactLoading',
    alertWith: 'box',
    onResolve() {
      context.$refs.form.reset();
    },
  });
};

const onUpdateUserPasswordClick = async (context) => {
  // TODO: add fetch timeout to axios
  context.$fn.exec(context, {
    ga: 'userPasswordResetRequested',
    store: {
      action: 'user/updateUserPassword',
      data: {
        email: context.$store.state.auth.user.email,
        password: context.oldPassword,
        newPassword: context.password,
      },
    },
    loader: 'updatingPasswordLoading',
    alertWith: 'toast',
    alertSuccessType: 'customOne',
    formValidation: true,
    onResolve() {
      context.$refs.formpw.reset();
    },
  });
};

const onProfilePictureClick = async (context) => {
  const formData = new FormData();
  formData.append('file', context.file);
  context.$fn.exec(context, {
    ga: 'userProfilePictureUpdateRequested',
    store: {
      action: 'user/updateProfilePicture',
      data: {
        formData,
      },
    },
    loader: 'fileLoading',
    alertWith: 'toast',
    alertSuccessSkip: true,
    onResolve(_data) {
      context.$fn.toggleAlert(context, 'toast', {
        message: _data.message,
        type: 'customOne',
      });
      localStorage.removeItem('profileImg');
      context.$auth.setUser(_data.body);
      context.picture = context.$config.mrBaseUrl + _data.body.profileImageURL;
    },
  });
};

const onProfileUpdateClick = async (context) => {
  // TODO: add fetch timeout to axios
  context.$fn.exec(context, {
    ga: 'userProfileUpdateRequested',
    store: {
      action: 'user/updateProfile',
      data: {
        fullName: context.fullName,
      },
    },
    loader: 'updatingProfileLoading',
    alertWith: 'toast',
    alertSuccessSkip: true,
    async onResolve(result) {
      if (context.picture && localStorage.getItem('profileImg')) {
        await onProfilePictureClick(context);
      } else {
        context.$fn.toggleAlert(context, 'toast', {
          message: result.message,
          type: 'customOne',
        });
        context.$auth.setUser(result.body.user);
      }
    },
  });
};

const saveImageToLocalStorage = (context) => {
  if (!context.file) return;
  const reader = new FileReader();
  reader.onload = () => {
    localStorage.setItem('profileImg', reader.result);
    context.picture = reader.result;
  };
  reader.readAsDataURL(context.file);
};

const onMountUserProfileInit = async (context) => {
  const profileImageUrl = context.$fnStore.getUserProp(
    context,
    'profileImageURL',
    true
  );
  context.fullName = context.$fnStore.getUserProp(context, 'fullName', true);
  context.picture = profileImageUrl
    ? context.$config.mrBaseUrl + profileImageUrl
    : localStorage.getItem('profileImg');

  context.$fn.exec(context, {
    ga: 'userAppsListRequested',
    store: {
      action: 'user/loadApps',
      data: {},
    },
    alertWith: 'toast',
    alertSuccessSkip: context,
    async onResolve(result) {
      context.apps = result.body.user.subscriptions;
      context.$fn.log(context.apps);
    },
  });
};

const onPasswordResetRequestClick = async (context) => {
  context.$fn.exec(context, {
    ga: 'userPasswordResetRequested',
    store: {
      action: 'user/requestPasswordRecoverToken',
      data: {
        email: context.email,
      },
    },
    formValidation: true,
    recaptchaValidation: true,
    loader: 'loader',
    alertWith: 'box',
  });
};

const onCheckRepeatedPassword = (context) => {
  if (context.password === context.repeatPassword)
    context.repeatPasswordSuccess = context.$t(
      'reset_password.repeat_password_success'
    );
};

const onSaveNewPasswordClick = async (context) => {
  if (context.$fn.verifyURLParams(context, 'token')) {
    await context.$fn.exec(context, {
      ga: 'userPasswordResetRequestedAfterCheck',
      store: {
        action: 'user/verifyNewPassword',
        data: {
          uuid: context.id,
          password: context.password,
          token: context.token,
        },
      },
      async onResolve() {
        context.$fn.redirect(context, 'login');
      },
      async onError(error) {
        if (error.status === 498) {
          context.$fn.redirect(context, 'passwordReset');
        }
      },
      alertWith: 'toast',
      formValidation: true,
    });
  }
};

const onProductSelectionClick = (context, event) => {
  const selectedPlan = event.productUUID;
  const selectedProduct = event.productShortCode;
  context.$fn.exec(context, {
    ga: 'productPricingSelected',
    store: {
      action: 'global/setProductAndPlan',
      data: { plan: selectedPlan, product: selectedProduct },
    },
    onResolve() {
      context.$fnStore.updateUserProp(context, {
        property: 'selectedPlan',
        value: selectedPlan,
      });
      context.$fnStore.updateUserProp(context, {
        property: 'selectedProduct',
        value: selectedProduct,
      });
      context.$fnCore.storeDispatch(context, 'global/setProductDetails', {
        videoUrl: context.$fn.getPlanPropByProduct(context, {
          product: selectedProduct,
          plan: selectedPlan,
          property: 'videoUrl',
        }),
        slug: context.$fn.getPlanPropByProduct(context, {
          product: selectedProduct,
          plan: selectedPlan,
          property: 'urlSlug',
        }),
        name: context.$fn.getPlanPropByProduct(context, {
          product: selectedProduct,
          plan: selectedPlan,
          property: 'name',
        }),
      });
      if (context.$fnStore.isLoggedIn(context))
        context.$fn.redirect(context, 'signup', {
          by: context.$fn.getPlanTypeByProduct(
            context,
            selectedProduct,
            selectedPlan
          ),
        });
      else context.$fn.redirect(context, 'signup');
    },
  });
};

const onMountedCreateStripeSession = (context) => {
  const promoCode = context.$cookiz.get('__MOTIONROUTES_REFERRAL_ID')
    ? (
        context.$cookiz.get('__MOTIONROUTES_REFERRAL_ID').ref +
        context.$cookiz.get('__MOTIONROUTES_REFERRAL_ID').cp
      ).toUpperCase()
    : null;

  setTimeout(async () => {
    await context.$fn.exec(context, {
      ga: 'stripeSessionRequested',
      store: {
        action: 'user/requestStripeSessionCreation',
        data: {
          productUUID: context.selectedPlan,
          promoCode: promoCode,
        },
      },
      alertWith: 'toast',
      alertSuccessSkip: true,
      async onResolve(data) {
        context.$stripe.redirectToCheckout({ sessionId: data.id });
      },
      async onError(error) {
        context.$fn.toggleAlert(context, 'toast', {
          message: error,
        });
      },
    });
  }, 3000);
};
const onMountedRedirectToDashboard = (context) => {
  setTimeout(async () => {
    window.location.href = context.$config.mrDashboardBaseUrl;
  }, 3000);
};

const verifyDownloadToken = (context, options) => {
  context.$fn.warn(options.downloadToken);
  context.$fn.warn(options.downloadUUID);
  context.$fn.exec(context, {
    ga: 'freeAppDownloadRequested', // TODO: switch between free and paid ga
    store: {
      action: 'user/verifyDownloadToken',
      data: {
        downloadToken: options.downloadToken,
        uuid: options.downloadUUID,
      },
    },
    alertWith: 'toast',
    alertSuccessSkip: true,
    onResolve(data) {
      if (data.body === 'ok') {
        //* if response is ok => unlock the start button
        // eslint-disable-next-line no-return-assign
        setTimeout(() => (context.startDownload = true), 3000);
      } else {
        context.$fn.err('Your download token is invalid!');
        //* else show error message
      }
    },
  });
};

const initDownloadPageUI = (context, options) => {
  context.appName = context.$fn.getPlanPropByProduct(context, {
    product: options.selectedProduct,
    plan: options.selectedPlan,
    property: 'name',
  });
  context.downloadToken = options.selectedSubscription.downloadToken;
  context.downloadUUID = options.selectedSubscription.uuid;
  context.downloadLink = `${context.$config.mrApiBaseUrl}/download/${context.downloadUUID}/${context.downloadToken}`;
};

const onMountedDownloadApplication = async (context) => {
  const selectedPlan =
    context.$fn.getURLParamValue('pid') ||
    context.$fnStore.getProp(context, {
      store: 'global',
      property: 'selectedPlan',
    });
  const selectedProduct =
    context.$fn.getProductByPlan(context, selectedPlan) ||
    context.$fnStore.getProp(context, {
      store: 'global',
      property: 'selectedProduct',
    });

  const selectedPlanObject = context.$fn.getProductObjectByPlan(
    context,
    selectedPlan
  );
  const selectedPlanType = selectedPlanObject
    ? selectedPlanObject.type
    : undefined;

  const affiliateUserName = context.$cookiz.get('__MOTIONROUTES_REFERRAL_ID')
    ? context.$cookiz.get('__MOTIONROUTES_REFERRAL_ID').ref
    : null;

  // TODO: implement login/inc= download and push the selected plan to the store.
  if (selectedPlan) {
    //* Update the store with the selected plan and product
    context.$fnCore.storeDispatch(context, 'global/setProductAndPlan', {
      plan: selectedPlan,
      product: selectedProduct,
    });
    context.$fnCore.storeDispatch(context, 'global/setProductDetails', {
      videoUrl: context.$fn.getPlanPropByProduct(context, {
        product: selectedProduct,
        plan: selectedPlan,
        property: 'videoUrl',
      }),
      slug: context.$fn.getPlanPropByProduct(context, {
        product: selectedProduct,
        plan: selectedPlan,
        property: 'urlSlug',
      }),
      name: context.$fn.getPlanPropByProduct(context, {
        product: selectedProduct,
        plan: selectedPlan,
        property: 'name',
      }),
    });
    //* if a product is selected
    context.$fn.log(`one product is selected${selectedPlan}`);
    const selectedSubscription = context.$fnStore
      .getProp(context, {
        store: 'auth.user',
        property: 'subscriptions',
      })
      .find(({ product }) => product.uuid === selectedPlan);

    if (selectedSubscription) {
      //* if the selected product is already in the user subscriptions list
      context.$fn.log(
        'The selected product is already in the user subscriptions list'
      );
      //* init the UI
      context.$fn.log('initializing the UI');
      initDownloadPageUI(context, {
        selectedProduct,
        selectedPlan,
        selectedSubscription: {
          ...selectedSubscription,
          downloadToken:
            context.$fn.getURLParamValue('token') ||
            selectedSubscription.downloadToken,
        },
      });
      verifyDownloadToken(context, {
        downloadToken:
          context.$fn.getURLParamValue('token') ||
          selectedSubscription.downloadToken,
        downloadUUID: selectedSubscription.uuid,
      });
    } else {
      //* the selected product is not in the user subscriptions
      context.$fn.warn('The selected product is not in the user subscriptions');
      if (selectedPlanType === 'free') {
        //* subscribe to the selected product
        context.$fn.warn('Subscribing to the selected product!');
        await context.$fn.exec(context, {
          ga: 'freeSubscriptionRequested',
          store: {
            action: 'user/subscribeToProduct',
            data: {
              uuid:
                context.$fn.getURLParamValue('p') ||
                context.$fnStore.getProp(context, {
                  store: 'global',
                  property: 'selectedPlan',
                }),
              affiliateUserName: affiliateUserName,
            },
          },
          alertWith: 'box',
          alertSuccessSkip: true,
          async onResolve(data) {
            context.$fnCore.storeDispatch(
              context,
              'user/setSubscription',
              data.body.subscriber
            );
            await context.$auth.fetchUser();
            initDownloadPageUI(context, {
              selectedProduct,
              selectedPlan,
              selectedSubscription: data.body.subscriber,
            });
            verifyDownloadToken(context, {
              downloadToken: data.body.subscriber.downloadToken,
              downloadUUID: data.body.subscriber.uuid,
            });
          },
        });
      }
      if (selectedPlanType === 'paid') {
        context.$fn.redirect(context, 'home');
        context.$fn.err('You are not subscribing to this product yet!');
        context.$fn.toggleAlert(context, 'toast', {
          message: 'Please select a product to download!',
          type: 'error',
        });
      }
    }
  } else {
    //* no product is selected
    context.$fn.redirect(context, 'home');
    context.$fn.err('No selected product was found!');
    context.$fn.toggleAlert(context, 'toast', {
      message: 'Please select a product to download!',
      type: 'error',
    });
  }
};

const onDownloadClickRedirect = (context) => {
  setTimeout(() => context.$fn.redirect(context, 'thankyou'), 2000);
};

export {
  resendConfirmation,
  subscribeToFreeProduct,
  redirectToCheckout,
  saveImageToLocalStorage,
  onMountedVerifyEmail,
  onLoginClick,
  onChangeCheckInputValidity,
  onCheckPasswordInputStrength,
  onCheckPasswordStrength,
  onSignupClick,
  onAffiliateSignupClick,
  onContactClick,
  onDownloadClickRedirect,
  onUpdateUserPasswordClick,
  onProfilePictureClick,
  onProfileUpdateClick,
  onMountUserProfileInit,
  onPasswordResetRequestClick,
  onCheckRepeatedPassword,
  onSaveNewPasswordClick,
  onProductSelectionClick,
  onMountedCreateStripeSession,
  onMountedRedirectToDashboard,
  onMountedDownloadApplication,
};
