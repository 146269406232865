export default async function ({ store, app }) {
  const cookieRes = app.$cookiz.get('__MOTIONROUTES_REFERRAL_ID');
  if (cookieRes && Object.keys(cookieRes).length > 0) {
    store.commit('global/SET_DISCOUNT', +cookieRes.cp);

    // const [result, error] = await store.dispatch(
    //   'global/getAffiliateDiscount',
    //   {
    //     userName: cookieRes.ref,
    //   }
    // );
    // if (result.body) {
    //   // store.commit('global/SET_DISCOUNT', result.body);
    //   store.commit('global/SET_DISCOUNT', +cookieRes.cp);
    // }
  }
}
