export default {
  signup: {
    route: '/user',
    verb: 'POST',
  },
  confirmRegen: {
    route: '/token/confirmation/regenerate',
    verb: 'POST',
  },
  confirmVerify: {
    route: '/token/confirmation/verify',
    verb: 'POST',
  },
  checkEmail: {
    route: '/email/',
    verb: 'GET',
  },
  checkUsername: {
    route: '/username/',
    verb: 'GET',
  },
  checkRecaptcha: {
    route: '/recaptcha/',
    verb: 'GET',
  },
  sendContactMessage: {
    route: '/contact',
    verb: 'POST',
  },
  uploadProfilePicture: {
    route: '/upload/picture/user',
    verb: 'POST',
  },
  updateUserPassword: {
    route: '/user/password',
    verb: 'PUT',
  },
  updateUserProfile: {
    route: '/user',
    verb: 'PUT',
  },
  getUserApps: {
    route: '/user/apps',
    verb: 'GET',
  },
  getBlogPost: {
    route: '/blog/',
    verb: 'GET',
  },
  incrementAffiliateClicks: {
    route: '/affiliate/clicks/',
    verb: 'POST',
  },
  affiliateDiscount: {
    route: '/affiliate/discount/',
    verb: 'GET',
  },
  passwordResetGenerate: {
    route: '/token/password-reset/generate',
    verb: 'POST',
  },
  passwordResetVerify: {
    route: '/token/password-reset/verify',
    verb: 'POST',
  },
  downloadTokenVerify: {
    route: '/token/download/verify',
    verb: 'POST',
  },
  downloadTokenGenerate: {
    route: '/token/download/generate',
    verb: 'POST',
  },
  startStripeSession: {
    route: '/stripe/session/',
    verb: 'POST',
  },
  subscribe: {
    route: '/subscribe/',
    verb: 'POST',
  },
};
