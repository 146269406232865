import slugify from 'slugify';

export default function ({ route, store, redirect }) {
  const path = route.path;

  // Check if the URL matches the expected structure
  const regex = /^\/(?:[a-z]{2})?\/blog\/posts\/(\d+)$/;
  const match = path.match(regex);
  if (match) {
    const postId = match[1];

    // Get the blog post title from the store based on the post ID
    const post = store.state.posts.list.find((post) => post.id === +postId);
    if (post) {
      const slug = slugify(post.title, { lower: true });

      // Get the language prefix from the route
      const languagePrefix = route.params.lang ? `/${route.params.lang}` : '';
      // Redirect to the correct URL
      redirect(`${languagePrefix}/blog/posts/${postId}/${slug}`);
    }
  }
}
