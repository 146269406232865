/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  selectedPlan: null, //! Deprecated
  selectedProduct: null, //! Deprecated
  isCookieAccepted: false, //! Deprecated
  isLoggedIn: false, //! Deprecated
  jwt: null, //! Deprecated
  email: null,
  name: null,
  isEmailVerified: false,
  id: null,
  userName: null,
  userSubscription: null,
});

export const mutations = {
  SET_COOKIE(state, response) {
    state.isCookieAccepted = response;
  },
  SET_PLAN(state, plan) {
    state.selectedPlan = plan;
  },
  SET_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.userSubscription = subscription;
  },
  SET_USER_DATA(
    state,
    { isLoggedIn, jwt, email, name, isEmailVerified, id, userName }
  ) {
    state.isLoggedIn = isLoggedIn;
    state.jwt = jwt;
    state.email = email;
    state.name = name;
    state.isEmailVerified = isEmailVerified;
    state.id = id;
    state.userName = userName;
  },
};

export const actions = {
  setPlan({ commit }, plan) {
    commit('SET_PLAN', plan);
  },
  setProduct({ commit }, product) {
    commit('SET_PRODUCT', product);
    return [true, undefined];
  },
  setSubscription({ commit }, subscription) {
    commit('SET_SUBSCRIPTION', subscription);
  },
  async requestNewEmailVerificationToken({ commit }, { email }) {
    return this.$fn.callMainAPI(this, 'confirmRegen', { email });
  },
  async requestEmailVerificationToken({ commit }) {
    const [data, dataError] = await this.$helpers.handle(
      this.$mrApi.$post('/token/confirmation/generate')
    );
    if (dataError) return [undefined, dataError.response.data.error];
    else return [data.message, undefined];
  },
  async verifyEmailVerificationToken({ commit }, { token, uuid }) {
    return this.$fn.callMainAPI(this, 'confirmVerify', { token, uuid });
  },
  async verifyDownloadToken({ commit }, { downloadToken, uuid }) {
    return this.$fn.callMainAPI(
      this,
      'downloadTokenVerify',
      { downloadToken, uuid },
      { onlyData: true }
    );
  },
  async requestPasswordRecoverToken({ commit }, { email }) {
    return this.$fn.callMainAPI(this, 'passwordResetGenerate', {
      email,
    });
  },
  async requestStripeSessionCreation({ commit }, { productUUID, promoCode }) {
    return this.$fn.callMainAPI(
      this,
      'startStripeSession',
      { promoCode },
      { params: productUUID, onlyData: true }
    );
  },
  async requestApplicationDownloadLink({ commit }, { subscriptionUUID }) {
    return this.$fn.callMainAPI(
      this,
      'downloadTokenGenerate',
      {
        subscriptionUUID,
      },
      { onlyData: true }
    );
  },
  async requestStripeCustomerPortal({ commit }, { stripeCustomerId }) {
    const [data, dataError] = await this.$helpers.handle(
      this.$mrApi.$post('/stripe/portal/session', {
        stripeCustomerId,
      })
    );
    if (dataError) return [undefined, dataError.response.data.error];
    else return [data, undefined];
  },
  async sendContactMessage(
    { commit },
    { selectedTopic, selectedApp, name, email, message, issue }
  ) {
    return this.$fn.callMainAPI(this, 'sendContactMessage', {
      selectedTopic,
      selectedApp,
      name,
      email,
      message,
      issue,
    });
  },
  async verifyNewPassword({ commit }, { uuid, password, token }) {
    return this.$fn.callMainAPI(this, 'passwordResetVerify', {
      uuid,
      password,
      token,
    });
  },
  async subscribeToProduct({ commit }, { uuid, affiliateUserName }) {
    return this.$fn.callMainAPI(
      this,
      'subscribe',
      { affiliateUserName },
      { params: uuid, onlyData: true }
    );
  },
  async login({ commit }, { email, password }) {
    const [data, dataError] = await this.$helpers.handle(
      this.$auth.loginWith('local', { data: { email, password } })
    );
    if (dataError) return [undefined, dataError.response.data.error];
    if (data) return ['Welcome! You are now logged in.', undefined];
  },
  async signup({ commit }, user) {
    const [dataSignup, dataSignupError] = await this.$helpers.handle(
      this.$mrApi.$post('/user', user)
    );
    if (dataSignupError) {
      return [undefined, dataSignupError.response.data.error];
    }
    if (dataSignup) {
      const [data, dataError] = await this.$helpers.handle(
        this.$auth.loginWith('local', { data: user })
      );
      if (dataError) return [undefined, dataError.response.data.error];
      if (data) {
        return [dataSignup.message, undefined];
      }
    }
  },
  async affiliateSignup({ commit }, affiliate) {
    const [dataSignup, dataSignupError] = await this.$helpers.handle(
      this.$mrApi.$post('/affiliate', affiliate)
    );
    if (dataSignupError) {
      return [undefined, dataSignupError.response.data.error];
    }
    if (dataSignup) {
      const [data, dataError] = await this.$helpers.handle(
        this.$auth.loginWith('local', { data: affiliate.payload.personal })
      );
      if (dataError) return [undefined, dataError.response.data.error];
      if (data) {
        return [dataSignup.message, undefined];
      }
    }
  },
  async updateProfile({ commit }, { fullName }) {
    return this.$fn.callMainAPI(
      this,
      'updateUserProfile',
      { fullName },
      { onlyData: true }
    );
  },
  async updateProfilePicture({ commit }, { formData }) {
    return this.$fn.callMainAPI(this, 'uploadProfilePicture', formData, {
      headers: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      onlyData: true,
    });
  },
  async updateUserPassword({ commit }, { password, newPassword, email }) {
    return this.$fn.callMainAPI(
      this,
      'updateUserPassword',
      { password, newPassword },
      {
        async onResolve(dataMessage) {
          const [data, dataError] = await this.$helpers.handle(
            this.$auth.loginWith('local', {
              data: { email, password: newPassword },
            })
          );
          if (dataError) return [undefined, dataError.response.data.error];
          return [dataMessage, undefined];
        },
      }
    );
  },
  async loadApps() {
    return this.$fn.callMainAPI(this, 'getUserApps', {}, { onlyData: true });
  },
  async getBlogPost({ commit }, { id }) {
    return this.$fn.callMainAPI(
      this,
      'getBlogPost',
      {},
      { params: id, onlyData: true }
    );
  },
};
