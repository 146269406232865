export default function({ store, redirect, $notifier, app }) {
  if (store.state.auth.loggedIn) {
    if (store.state.auth.user.status == "non_verified") {
      $notifier.showMessage({
        content: app.i18n.t("signup.email_not_verified"),
        color: "error",
        timeout: 5000
      });
      return redirect("/");
    }
  }
}
