import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ButtonOverFlowButton: () => import('../../components/UI/Button/OverFlowButton.vue' /* webpackChunkName: "components/button-over-flow-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonSubmitButton: () => import('../../components/UI/Button/SubmitButton.vue' /* webpackChunkName: "components/button-submit-button" */).then(c => wrapFunctional(c.default || c)),
  HyperLinkForgetPassword: () => import('../../components/UI/HyperLink/ForgetPassword.vue' /* webpackChunkName: "components/hyper-link-forget-password" */).then(c => wrapFunctional(c.default || c)),
  HyperLinkHaveDontHaveAccount: () => import('../../components/UI/HyperLink/HaveDontHaveAccount.vue' /* webpackChunkName: "components/hyper-link-have-dont-have-account" */).then(c => wrapFunctional(c.default || c)),
  HyperLinkRecaptchaDisclaimer: () => import('../../components/UI/HyperLink/RecaptchaDisclaimer.vue' /* webpackChunkName: "components/hyper-link-recaptcha-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  HyperLinkSignupConsent: () => import('../../components/UI/HyperLink/SignupConsent.vue' /* webpackChunkName: "components/hyper-link-signup-consent" */).then(c => wrapFunctional(c.default || c)),
  HyperLinkSignupOrLogin: () => import('../../components/UI/HyperLink/SignupOrLogin.vue' /* webpackChunkName: "components/hyper-link-signup-or-login" */).then(c => wrapFunctional(c.default || c)),
  FormAutoComplete: () => import('../../components/UI/Form/AutoComplete.vue' /* webpackChunkName: "components/form-auto-complete" */).then(c => wrapFunctional(c.default || c)),
  FormDynamicTextInput: () => import('../../components/UI/Form/DynamicTextInput.vue' /* webpackChunkName: "components/form-dynamic-text-input" */).then(c => wrapFunctional(c.default || c)),
  FormEmailInput: () => import('../../components/UI/Form/EmailInput.vue' /* webpackChunkName: "components/form-email-input" */).then(c => wrapFunctional(c.default || c)),
  FormFileInput: () => import('../../components/UI/Form/FileInput.vue' /* webpackChunkName: "components/form-file-input" */).then(c => wrapFunctional(c.default || c)),
  FormIssueInput: () => import('../../components/UI/Form/IssueInput.vue' /* webpackChunkName: "components/form-issue-input" */).then(c => wrapFunctional(c.default || c)),
  FormPasswordInput: () => import('../../components/UI/Form/PasswordInput.vue' /* webpackChunkName: "components/form-password-input" */).then(c => wrapFunctional(c.default || c)),
  FormSignupEmailInput: () => import('../../components/UI/Form/SignupEmailInput.vue' /* webpackChunkName: "components/form-signup-email-input" */).then(c => wrapFunctional(c.default || c)),
  FormSignupNameInput: () => import('../../components/UI/Form/SignupNameInput.vue' /* webpackChunkName: "components/form-signup-name-input" */).then(c => wrapFunctional(c.default || c)),
  FormSignupPasswordInput: () => import('../../components/UI/Form/SignupPasswordInput.vue' /* webpackChunkName: "components/form-signup-password-input" */).then(c => wrapFunctional(c.default || c)),
  FormSignupUsernameInput: () => import('../../components/UI/Form/SignupUsernameInput.vue' /* webpackChunkName: "components/form-signup-username-input" */).then(c => wrapFunctional(c.default || c)),
  FormSwitchInput: () => import('../../components/UI/Form/SwitchInput.vue' /* webpackChunkName: "components/form-switch-input" */).then(c => wrapFunctional(c.default || c)),
  FormTextAreaInput: () => import('../../components/UI/Form/TextAreaInput.vue' /* webpackChunkName: "components/form-text-area-input" */).then(c => wrapFunctional(c.default || c)),
  FormTextInput: () => import('../../components/UI/Form/TextInput.vue' /* webpackChunkName: "components/form-text-input" */).then(c => wrapFunctional(c.default || c)),
  TypographyH1Title: () => import('../../components/UI/Typography/H1Title.vue' /* webpackChunkName: "components/typography-h1-title" */).then(c => wrapFunctional(c.default || c)),
  TypographyH2Title: () => import('../../components/UI/Typography/H2Title.vue' /* webpackChunkName: "components/typography-h2-title" */).then(c => wrapFunctional(c.default || c)),
  PageViewAffiliateSignupView: () => import('../../components/PageView/AffiliateSignupView.vue' /* webpackChunkName: "components/page-view-affiliate-signup-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewBlogPostView: () => import('../../components/PageView/BlogPostView.vue' /* webpackChunkName: "components/page-view-blog-post-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewContactView: () => import('../../components/PageView/ContactView.vue' /* webpackChunkName: "components/page-view-contact-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewDownloadView: () => import('../../components/PageView/DownloadView.vue' /* webpackChunkName: "components/page-view-download-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewEmailVerificationView: () => import('../../components/PageView/EmailVerificationView.vue' /* webpackChunkName: "components/page-view-email-verification-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewExpressionBasePricingView: () => import('../../components/PageView/ExpressionBasePricingView.vue' /* webpackChunkName: "components/page-view-expression-base-pricing-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewExpressionBaseView: () => import('../../components/PageView/ExpressionBaseView.vue' /* webpackChunkName: "components/page-view-expression-base-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewFAQView: () => import('../../components/PageView/FAQView.vue' /* webpackChunkName: "components/page-view-f-a-q-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewHomeView: () => import('../../components/PageView/HomeView.vue' /* webpackChunkName: "components/page-view-home-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewLoginView: () => import('../../components/PageView/LoginView.vue' /* webpackChunkName: "components/page-view-login-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewPasswordResetCheckView: () => import('../../components/PageView/PasswordResetCheckView.vue' /* webpackChunkName: "components/page-view-password-reset-check-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewPasswordResetView: () => import('../../components/PageView/PasswordResetView.vue' /* webpackChunkName: "components/page-view-password-reset-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewPrivacyPolicyView: () => import('../../components/PageView/PrivacyPolicyView.vue' /* webpackChunkName: "components/page-view-privacy-policy-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewRefundPolicyView: () => import('../../components/PageView/RefundPolicyView.vue' /* webpackChunkName: "components/page-view-refund-policy-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewSignupView: () => import('../../components/PageView/SignupView.vue' /* webpackChunkName: "components/page-view-signup-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewTermsOfServiceView: () => import('../../components/PageView/TermsOfServiceView.vue' /* webpackChunkName: "components/page-view-terms-of-service-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewThankYouView: () => import('../../components/PageView/ThankYouView.vue' /* webpackChunkName: "components/page-view-thank-you-view" */).then(c => wrapFunctional(c.default || c)),
  PageViewUserSettingsView: () => import('../../components/PageView/UserSettingsView.vue' /* webpackChunkName: "components/page-view-user-settings-view" */).then(c => wrapFunctional(c.default || c)),
  UIAlertBox: () => import('../../components/UI/AlertBox.vue' /* webpackChunkName: "components/u-i-alert-box" */).then(c => wrapFunctional(c.default || c)),
  UIAnnouncementBanner: () => import('../../components/UI/AnnouncementBanner.vue' /* webpackChunkName: "components/u-i-announcement-banner" */).then(c => wrapFunctional(c.default || c)),
  UIAppBar: () => import('../../components/UI/AppBar.vue' /* webpackChunkName: "components/u-i-app-bar" */).then(c => wrapFunctional(c.default || c)),
  UIAvatarCircle: () => import('../../components/UI/AvatarCircle.vue' /* webpackChunkName: "components/u-i-avatar-circle" */).then(c => wrapFunctional(c.default || c)),
  UIBlogContentSection: () => import('../../components/UI/BlogContentSection.vue' /* webpackChunkName: "components/u-i-blog-content-section" */).then(c => wrapFunctional(c.default || c)),
  UIBlogImageSection: () => import('../../components/UI/BlogImageSection.vue' /* webpackChunkName: "components/u-i-blog-image-section" */).then(c => wrapFunctional(c.default || c)),
  UIBlogMetaSection: () => import('../../components/UI/BlogMetaSection.vue' /* webpackChunkName: "components/u-i-blog-meta-section" */).then(c => wrapFunctional(c.default || c)),
  UIBlogSocialShareSection: () => import('../../components/UI/BlogSocialShareSection.vue' /* webpackChunkName: "components/u-i-blog-social-share-section" */).then(c => wrapFunctional(c.default || c)),
  UIBlogTitleSection: () => import('../../components/UI/BlogTitleSection.vue' /* webpackChunkName: "components/u-i-blog-title-section" */).then(c => wrapFunctional(c.default || c)),
  UICheckBox: () => import('../../components/UI/CheckBox.vue' /* webpackChunkName: "components/u-i-check-box" */).then(c => wrapFunctional(c.default || c)),
  UICountDownTimer: () => import('../../components/UI/CountDownTimer.vue' /* webpackChunkName: "components/u-i-count-down-timer" */).then(c => wrapFunctional(c.default || c)),
  UIFAQSection: () => import('../../components/UI/FAQSection.vue' /* webpackChunkName: "components/u-i-f-a-q-section" */).then(c => wrapFunctional(c.default || c)),
  UIFeatureCard: () => import('../../components/UI/FeatureCard.vue' /* webpackChunkName: "components/u-i-feature-card" */).then(c => wrapFunctional(c.default || c)),
  UILoadingBar: () => import('../../components/UI/LoadingBar.vue' /* webpackChunkName: "components/u-i-loading-bar" */).then(c => wrapFunctional(c.default || c)),
  UILoginForm: () => import('../../components/UI/LoginForm.vue' /* webpackChunkName: "components/u-i-login-form" */).then(c => wrapFunctional(c.default || c)),
  UIMobilePageStepper: () => import('../../components/UI/MobilePageStepper.vue' /* webpackChunkName: "components/u-i-mobile-page-stepper" */).then(c => wrapFunctional(c.default || c)),
  UIOverviewFeature: () => import('../../components/UI/OverviewFeature.vue' /* webpackChunkName: "components/u-i-overview-feature" */).then(c => wrapFunctional(c.default || c)),
  UIPageStepper: () => import('../../components/UI/PageStepper.vue' /* webpackChunkName: "components/u-i-page-stepper" */).then(c => wrapFunctional(c.default || c)),
  UIPersistantAlertBox: () => import('../../components/UI/PersistantAlertBox.vue' /* webpackChunkName: "components/u-i-persistant-alert-box" */).then(c => wrapFunctional(c.default || c)),
  UIProgressBar: () => import('../../components/UI/ProgressBar.vue' /* webpackChunkName: "components/u-i-progress-bar" */).then(c => wrapFunctional(c.default || c)),
  UISharePostSection: () => import('../../components/UI/SharePostSection.vue' /* webpackChunkName: "components/u-i-share-post-section" */).then(c => wrapFunctional(c.default || c)),
  UISkeletonLoader: () => import('../../components/UI/SkeletonLoader.vue' /* webpackChunkName: "components/u-i-skeleton-loader" */).then(c => wrapFunctional(c.default || c)),
  UISnackbar: () => import('../../components/UI/Snackbar.vue' /* webpackChunkName: "components/u-i-snackbar" */).then(c => wrapFunctional(c.default || c)),
  UIUICounter: () => import('../../components/UI/UICounter.vue' /* webpackChunkName: "components/u-i-u-i-counter" */).then(c => wrapFunctional(c.default || c)),
  UIUICounterSheetBased: () => import('../../components/UI/UICounterSheetBased.vue' /* webpackChunkName: "components/u-i-u-i-counter-sheet-based" */).then(c => wrapFunctional(c.default || c)),
  UIVersionCard: () => import('../../components/UI/VersionCard.vue' /* webpackChunkName: "components/u-i-version-card" */).then(c => wrapFunctional(c.default || c)),
  UIVideoPlayer: () => import('../../components/UI/VideoPlayer.vue' /* webpackChunkName: "components/u-i-video-player" */).then(c => wrapFunctional(c.default || c)),
  UICardAdditionalFeaturesCard: () => import('../../components/UI/Card/AdditionalFeaturesCard.vue' /* webpackChunkName: "components/u-i-card-additional-features-card" */).then(c => wrapFunctional(c.default || c)),
  UICardBlogPostCard: () => import('../../components/UI/Card/BlogPostCard.vue' /* webpackChunkName: "components/u-i-card-blog-post-card" */).then(c => wrapFunctional(c.default || c)),
  UICardCancelTransactionCard: () => import('../../components/UI/Card/CancelTransactionCard.vue' /* webpackChunkName: "components/u-i-card-cancel-transaction-card" */).then(c => wrapFunctional(c.default || c)),
  UICardConfirmEmailCard: () => import('../../components/UI/Card/ConfirmEmailCard.vue' /* webpackChunkName: "components/u-i-card-confirm-email-card" */).then(c => wrapFunctional(c.default || c)),
  UICardPricingCard: () => import('../../components/UI/Card/PricingCard.vue' /* webpackChunkName: "components/u-i-card-pricing-card" */).then(c => wrapFunctional(c.default || c)),
  UICardStickyHeaderCard: () => import('../../components/UI/Card/StickyHeaderCard.vue' /* webpackChunkName: "components/u-i-card-sticky-header-card" */).then(c => wrapFunctional(c.default || c)),
  UICardSuccessTransactionCard: () => import('../../components/UI/Card/SuccessTransactionCard.vue' /* webpackChunkName: "components/u-i-card-success-transaction-card" */).then(c => wrapFunctional(c.default || c)),
  Snackbar: () => import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c)),
  LearnMorePageVideoTutorial: () => import('../../components/LearnMorePage/VideoTutorial.vue' /* webpackChunkName: "components/learn-more-page-video-tutorial" */).then(c => wrapFunctional(c.default || c)),
  SettingsUIAppCard: () => import('../../components/SettingsUI/AppCard.vue' /* webpackChunkName: "components/settings-u-i-app-card" */).then(c => wrapFunctional(c.default || c)),
  SettingsUIPasswordSettingsView: () => import('../../components/SettingsUI/PasswordSettingsView.vue' /* webpackChunkName: "components/settings-u-i-password-settings-view" */).then(c => wrapFunctional(c.default || c)),
  SettingsUIProfileSettingsView: () => import('../../components/SettingsUI/ProfileSettingsView.vue' /* webpackChunkName: "components/settings-u-i-profile-settings-view" */).then(c => wrapFunctional(c.default || c)),
  SettingsUIUserAppsSettingsView: () => import('../../components/SettingsUI/UserAppsSettingsView.vue' /* webpackChunkName: "components/settings-u-i-user-apps-settings-view" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
