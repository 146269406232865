export default async ({ $config }, locale) => {
  return await Promise.resolve({
    hello: 'Welcome back ',
    bye: 'Take care and see you soon!',
    close: 'Close',
    banner: [
      `🔥 Black Friday & Cyber Monday 60% OFF 🔥 Lifetime Access to EXPRESSION BASE deal ends in`,
      `Get Deal`,
      `d`,
      `h`,
      `m`,
      `s`,
    ],
    common: {
      recaptcha: [
        'This site is protected by reCAPTCHA and the Google',
        'Privacy Policy',
        'and',
        'Terms of Service',
        'apply.',
      ],
      credits: ['Motion Routes LTD', '. All Rights Reserved'],
      email_errors: ['E-mail is required', 'E-mail must be valid'],
      name_errors: [
        'Name is required',
        'Name must be at most 20 characters long',
      ],
      username_errors: [
        'Username is required',
        'Username must be at most 15 characters long',
        'Username must be at least 3 characters long',
        'Username must contains only letters and numbers',
      ],
      password_errors: [
        'Password is required',
        'Password must be at least 8 characters long',
      ],
    },
    pages: {
      home: 'Home',
      contact: 'Contact',
      eb: 'Expression Base',
      resources: 'Resources',
      blog: 'Blog',
      faq: 'FAQ',
      docs: 'Docs',
      settings: 'Settings',
      aff: 'Become Affiliate',
      dev: 'For Developers',
      lic: 'Terms of Service',
      refund: 'Refund Policy',
      privacy: 'Privacy Policy',
    },

    buttons: {
      login: 'Login',
      signup: 'Signup',
      logout: 'Logout',
      download_now: 'Download Now',
      learn_more: 'Learn More',
      start_now: 'Start Now',
    },
    index: {
      title1: 'Speed up',
      title2: 'your After Effects Workflow',
      subtitle1: 'Instant Animations with',
      subtitle2: 'Expression Base ',
      subtitle3: '. Purpose-built for Animators and MOgraph Designers.',
      cards: [
        {
          title: 'Faster Animation Workflow',
          description:
            'Use Adobe After Effects Expression Engine to render your animation automatically without the need for unnecessary keyframes.',
        },
        {
          title: 'Public Expressions For Everyone',
          description:
            'Access Top level Public Expressions from our After Effects Gurus to enhance and speed up your animation workflow.',
        },
        {
          title: 'Massive Expressions Injection',
          description:
            'Inject endless amount of expressions into endless amount of properties within endless amount of layers.',
        },
        {
          title: 'Smart Expression Controls',
          description:
            'Use our predefined Smart Expression Controls to instantly name, create and link your expressions to any expression control.',
        },
        {
          title: 'Access Expressions From Anywhere',
          description:
            'Our highly available servers will ensure a fast and smooth user experience when using Expression Base from any device.',
        },
        {
          title: 'Master Layer Control',
          description:
            'Link all of your expressions and expressions controls within different properties and layers, to one single Master Layer Control.',
        },
      ],
      stats: [
        {
          title: 'Users Expressions',
        },
        {
          title: 'Public Expressions',
        },
        {
          title: 'Happy Users',
        },
      ],
      callout: 'Ready to take your workflow to the next level?',
    },
    login: {
      title: 'Login',
      email_placeholder: 'Your email',
      password_placeholder: 'Your password',
      remeberme: 'Remember Me',
      forget_password: 'Forgot Password?',
      donthave_account: 'Dont have an account?',
      signup_now: 'Signup Now',
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try to reload the page',
        'All fields are required!',
      ],
    },
    signup: {
      title: 'Create your account',
      name_placeholder: 'Your name*',
      email_placeholder: 'Your email*',
      username_placeholder: 'Your username*',
      password_placeholder: 'Your password*',
      alreadyhave_account: 'Already have an account?',
      email_hint: 'We will send you an email confirmation to this address',
      name_hint: 'No more than 20 characters',
      password_hint: 'Your password is strong like Graphene',
      username_hint: 'Must be between 3 and 15 characters long',
      username_available: 'This username is available',
      username_not_available: 'This username is not available',
      email_available: 'This email is available',
      email_not_available: 'This email is not available',
      password_score_error:
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      login_now: 'Login Now',
      consent: [
        'By registering, you agree to our',
        'Terms of Service ',
        ' and',
        ' Privacy Policy ',
      ],
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try again',
        'All fields are required!',
      ],
      email_not_verified:
        'You cannot download this application unless you verify your account. Please check for a confirmation link in your email or click on the Send Verification Link button above.',
    },
    affiliateSignup: {
      title: 'Join our affiliate program',
      name_placeholder: 'Your name*',
      email_placeholder: 'Your email*',
      username_placeholder: 'Your username*',
      password_placeholder: 'Your password*',
      alreadyhave_account: 'Already have an account?',
      email_hint: 'We will send you an email confirmation to this address',
      name_hint: 'No more than 20 characters',
      password_hint: 'Your password is strong like Graphene',
      username_hint: 'Must be between 3 and 15 characters long',
      username_available: 'This username is available',
      username_not_available: 'This username is not available',
      email_available: 'This email is available',
      email_not_available: 'This email is not available',
      password_score_error:
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      login_now: 'Login Now',
      consent: [
        'By registering, you agree to our',
        'Terms of Service ',
        ' and',
        ' Privacy Policy ',
      ],
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try again',
        'All fields are required!',
      ],
      email_not_verified:
        'You cannot download this application unless you verify your account. Please check for a confirmation link in your email or click on the Send Verification Link button above.',
    },
    forget_password: {
      title: 'Forgot your password?',
      page_hint:
        'Enter your email address so we can send you a link to reset your password.',
      email_placeholder: 'Your email*',
      email_available: 'This email exists in our database.',
      email_not_available: 'This email does not exist in our database.',
      send_btn: 'send reset link',
      consent: ['You can also', ' Signup ', 'or ', 'Login'],
    },
    reset_password: {
      title: 'Reset your password',
      page_hint: 'Type your new password here.',
      password_placeholder: 'Your new password',
      password_repeat_placeholder: 'Repeat your new password',
      save_btn: 'save password',
      repeat_password_error: 'Passwords must be identical.',
      repeat_password_success: 'Good memory! your passwords are identical',
      invalid_token: 'Invalid Confirmation Token!',
    },
    contact: {
      title: 'Contact Us',
      q1: 'I am submitting a',
      q2: 'I am using',
      issue: 'Your subject*',
      message_placeholder: 'Your message*',
      issue_errrors: [
        'Subject is required',
        'Subject must be at most 30 characters long',
      ],
      message_errrors: [
        'Message is required',
        'Message must be at most 500 characters long',
      ],
      issue_hint: 'Few words to describe the motivation behind your message.',
      topics: ['Bug Report', 'Feature Request', 'Discussion'],
      email_hint: 'We will be communicating with you via this email address.',
      message_hint: 'Please be as concise as possible.',
      send_btn: 'Send',
    },
    faq: {
      title: 'Frequently Asked Questions',
      list: [
        {
          title: 'Account',
          content: [
            {
              question: 'Why I need to create an account on Motion Routes?',
              answer: `Every user must have a unique account on Motion Routes in order to keep records about our fellow users and prevent any spam abuse that may happen.`,
            },
            {
              question: 'How do I delete my account?',
              answer: `Contact the support team via our contact page or directly via email: <a href="mailto:${$config.mrEmailSupport}"<b>${$config.mrEmailSupport}</b></a>.`,
            },
            {
              question:
                "Can I update my account's information after I sign up?",
              answer: `Click on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> Profile.</br>
            You can only update your account's name and profile image.</br>
            Email address and username cannot be updated after sign up, because our system count on these records in all of our internal processes.`,
            },
            {
              question: 'Can I update my password?',
              answer: `Click on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> Security.</br>
            You can only update your account's password under the Password section.`,
            },
            {
              question: 'Can I share my account with others?',
              answer: `Account sharing is prohibited on Motion Routes platform and may lead to permanent account ban.`,
            },
          ],
        },
        {
          title: 'License and Using Items',
          content: [
            {
              question: 'How to Upgrade from free to paid plan?',
              answer: `You can simply click on the upgrade button inside the Adobe HTML extension or directly buy the paid version <em>with the same account</em>.</br>
            <em>Upgrading from free to paid plan with the same account will automatically update your account and bring all your old data from the free plan to the paid version.</em>`,
            },
            {
              question: 'Can I share my license with others?',
              answer: `License sharing is prohibited on all Motion Routes products and may result in Software License Violation which may lead to permanent account ban.</br>
            License sharing is only allowed on Team License for certain products.`,
            },
            {
              question: 'Can I find an old item license?',
              answer: `You can find any item license that you have already bought to by clicking on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> My Apps.</br>`,
            },
            {
              question: 'Can I use my license on more than one machine?',
              answer: `You can use your license on more than one machine if this feature is already included in the license. `,
            },
            {
              question: 'How can I report a broken item?',
              answer: `If you are experiencing issues while using your item, first recheck the item features and descriptions.</br>
            If you believe that the item is not working correctly as described, you can report the issue to the support team either by visiting the contact page or direct email to <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a>`,
            },
          ],
        },
        {
          title: 'General',
          content: [
            {
              question: 'What are the supported countries by Motion Routes?',
              answer: `Motion Routes is a global platform which operate on multiple countries, however certain countries are not supported due to laws restrictions.</br>
            Here is the list of restricted countries by Motion Routes:</br>
            <ul>
            <li>Cuba</li>
            <li>Isreal</li>
            <li>Iran</li>
            <li>Crimea Region</li>
            <li>North Korea</li>
            </ul>
            Please note that this list is subject to change by the terms and conditions of Motion Routes.`,
            },
            {
              question: 'How can I sell on Motion Routes?',
              answer: `You can simply contact the Motion Routes support team via email: <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a></br>
            We will provide all the tools you need to use our API and sell on Motion Routes platform.`,
            },
            {
              question: 'How can I join an affiliate program on Motion Routes?',
              answer: `You can simply contact the Motion Routes support team via email: <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a></br>
            You need to include the following in your email:</br>
            <ul>
            <li>Traffic source (i.e: Youtube)</li>
            <li>Estimated monthly traffic</li>
            </ul>
            An email will be sent to you within the next 24 hours which states our response towards your application.`,
            },
            {
              question: 'Can I ask for a refund?',
              answer: `Refunds are only allowed under certain conditions:</br>
            <ul>
            <li>Purchase was made for no more than 72 hours</li>
            <li>You did not subscribed to the free plan of the same item</li>
            </ul>
            Otherwise, we do not allow refunds on paid items which was bought after subscribing to a free plan, as we believe that customers have fully tried the item beforehand.`,
            },
          ],
        },

        {
          title: 'Installation',
          content: [
            {
              question: 'How to download an item on Motion Routes?',
              answer: `After successfully registering your account in Motion Routes, you can simply download any item by clicking the download button.</br>
            Or you can download any item that you have already subscribed to by clicking on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> My Apps.</br>`,
            },
            {
              question: 'How to install an Adobe HTML Extension?',
              answer: `<em>1) Automatic Mode: </em></br>
            Adobe HTML Extension are usually shipped in ZXP format. Thus you need a ZXP installer to install it correctly on your machine.</br>
            There are 2 free ZXP installers available on the market so far:</br>
            <ul>
            <li><a href="https://zxpinstaller.com/">Open Source ZXPInstaller</a> <i>by @maxoos</i></li>
            <li><a href="https://aescripts.com/learn/zxp-installer/">AeScript ZXPInstaller</a> <i>by @aescripts+aeplugins</i></li>
            </ul>
            <b>We are currently working on our own ZXP installer which we will eventually post for free download.</b></br>
            Drag and drop your ZXP file into the ZXP installer and let it do its magic.</br>
            <em>2) Manual Mode: </em></br>
            Sometimes you need to manually install your Adobe HTML extension, thus you need to follow these steps:</br>
            <small>On Windows:</small></br>
            Rename your .zxp extension file to use .zip instead</br>
            Unzip the file, you should get a folder. Rename it if desired</br>
            Copy that folder into C:\Program Files (x86)\Common Files\Adobe\CEP\extensions\</br>
            Restart After Effects</br>
            Your extension should now show up under Window > Extensions</br>
            <small>On Mac:</small></br>
            Rename your .zxp extension file to use .zip instead.</br>
            Unzip the file, you should get a folder. Rename it if desired</br>
            Copy that folder into ~/Library/Application Support/Adobe/CEP/extensions</br>
            Restart After Effects</br>
            Your extension should now show up under Window > Extensions</br>`,
            },
          ],
        },
      ],
    },
    apps: {
      eb: {
        tabs: ['Overview', 'Changelogs'],
        author: 'Author',
        version: 'Version',
        rating: 'Rating',
        nb_installs: 'Number of installs',
        os_compatibility: 'Compatibility (OS)',
        app_compatibility: 'Compatibility (App)',
        size: 'File size',
        category: 'Category',
        last_update: 'Last update',
        full_features: 'Full Features',
        features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#online-expression-storage',
            img: '/../img/01.gif',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#public-expressions-access',
            img: '/../img/02.gif',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#search-after-effects-expressions-by-name-or-tags',
            img: '../img/07.gif',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#super-after-effects-expressions-preloading',
            img: '../img/08.gif',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#pull-and-push-expressions',
            img: '../img/09.gif',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#launch-eb-with-your-custom-shortcut',
            img: '../img/10.gif',
          },
          {
            feature: '8 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#smart-expression-controls',
            img: '../img/05.gif',
          },
          {
            feature: 'Unlimited Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#unlimited-expression-slots',
            img: '../img/06.gif',
          },
          // {
          //   feature: "60 days Persistent Login",
          //   hint:
          //     "Number of days you can access the application without login again.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/c4e2b69cd0003c248302fabe9500c9d4acd67c86/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f4b696e657469635f53697a652e676966"
          // },
          {
            feature: 'Multiple Expressions Setup',
            hint: 'Apply as many expressions as you want to one or many layers with one single click.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#multiple-expressions-setup',
            img: '../img/03.gif',
          },
          {
            feature: 'Master Layer Expressions Control',
            hint: 'Combine and store all of your expression controls in one single master layer. (Super useful for template creators)',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#master-layer-expressions-control',
            img: '../img/04.gif',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
            learn_more_url:
              $config.mrDocsBaseUrl +
              '/expression-base#share-expressions-to-public',
            img: '../img/soon.gif',
          },
          {
            feature: 'Expressions for Teams',
            hint: 'Store, access and share your expressions with your own team members.',
            learn_more_url:
              $config.mrDocsBaseUrl + '/expression-base#expressions-for-teams',
            img: '../img/soon.gif',
          },
          // {
          //   feature: "Premium Support",
          //   hint: "Get help through our online customer support service.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/748b39fbe26d3ca82247c2e12a292505c4fc3764/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f4b696e657469635f436f6c6f722e676966"
          // },
          // {
          //   feature: "Lifetime Updates",
          //   hint: "Get our latest versions of our stable app.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/4b8a79809f6ddec308fa93d7194b67723e400c7c/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f53747265616d5f4368617474696e675f4261636b67726f756e645f312e676966"
          // }
        ],
        change_logs: [
          {
            logs: ['Improved app security'],
            version: '1.0.2',
            release_date: 'Aug 22, 2022',
          },
          {
            logs: ['Fixed Bad request issue while activating the app.'],
            version: '1.0.1',
            release_date: 'May 23, 2022',
          },
          {
            logs: ['First Release'],
            version: '1.0.0',
            release_date: 'May 15, 2022',
          },
        ],
        overview: {
          stats_title: 'Latest Application Stats',
          stats: [
            {
              title: 'Public Expressions',
              value: '108',
            },
            {
              title: 'Users Expressions',
              value: '7412',
            },
            {
              title: 'Happy Users',
              value: '2144',
            },
          ],
        },
        thankyou_page: {
          // h1: 'Thank you for Downloading Expression Base ✌️',
          h1: 'Thank you for Downloading ',
          h2: ' ✌️',
          p1: 'Please check our 📑',
          p2: 'Getting Started Guide',
          // p3: 'for more information on how to use Expression Base.',
          p3: 'for more information on how to use ',
          p4: '📢 Do you still have any questions? You can check our',
          p5: 'FAQ',
          p6: 'section or',
          p7: 'Contact us',
          p8: 'directly.',
        },
        pricing_model: 'fremium',
        pricing: [
          {
            title: 'Free',
            price: '0.00',
            hint: '*Free Forever',
            popular: false,
          },
          {
            title: 'Advanced',
            price: '2.99',
            hint: '*Billed Monthly',
            popular_text: 'Most Popular',
            popular: true,
          },
          {
            title: 'Premium',
            price: '69.99',
            hint: '*Lifetime Purchase',
            popular: false,
          },
        ],
        paid_pricing_features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top-level expressions that are made by our well-known authors.',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow.',
          },
          {
            feature: '8 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
          },
          {
            feature: 'Unlimited Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
          },
          {
            feature: 'Multiple Expressions Setup',
            hint: 'Apply as many expressions as you want to one or many layers with one single click.',
          },
          {
            feature: 'Master Layer Expressions Control',
            hint: 'Combine and store all of your expression controls in one single master layer. (Super useful for template creators)',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Expressions for Teams (soon)',
            hint: 'Store, access and share your expressions with your own team members.',
          },
          {
            feature: 'Premium Support',
            hint: 'Get help through our online customer support service.',
          },
          {
            feature: 'Lifetime Updates',
            hint: 'Get our latest versions of our stable app.',
          },
        ],
        free_pricing_features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors.',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow.',
          },
          {
            feature: '2 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
          },
          {
            feature: '10 Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Lifetime Updates',
            hint: 'Get our latest versions of our stable app.',
          },
        ],
        common_pricing_hint: 'All Plans include these benefits:',
        common_pricing_features: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors',
          },
          {
            feature: 'Forum/FAQ Support',
            hint: 'Get help through our Forums and/or our Frequently Asked Questions section.',
          },
        ],
        checkout: {
          pro: {
            stripe_notice: 'You will be redirected to Stripe Secure Checkout',
            timer_consent: ['redirecting in', 'seconds...'],
          },
        },
      },
    },
    pricing_page: {
      header: ['Start for free.', 'Upgrade anytime.'],
      steps: ['Choose Your Plan', 'Create An Account', 'Confirm Your Email'],
    },
    email_confirmation_page: {
      h1: 'Confirm your e-mail',
      p1: 'Action needed:',
      p2: ' Confirm your email',
      action:
        'In order to confirm your account, we have sent you a link to this email address:',
      did_not_get_email: 'Didnt get an email yet?',
      resend_verification_email: 'Send email confirmation again',
    },
    email_verification_page: {
      h1: 'Activation Status',
      h2: 'Activate Your Account',
      resend_verification_email: 'resend confirmation link',
      loading: 'Verifying your email address...',
    },
    transaction: {
      success: {
        consent: 'Transaction Succeeded',
        p1: 'Action needed:',
        p2: 'Check your email',
        p3: `🎉 Congratulations! You have successfully purchased the paid version of Expression Base.
       We have sent you an email to this email address`,
        p4: 'containing your',
        p5: 'license key',
        p6: 'and your',
        p7: 'custom download link',
        p8: 'to download the app.',
        did_not_get_email: "Didn't get an email yet?",
        send_email: 'Send email again',
      },
      cancel: {
        consent: 'Transaction Canceled',
        p1: `⚠️ You have canceled this transaction. If you think that this
      action has been made by mistake, please feel free to`,
        p2: 'purchase',
        p3: 'the app again. Otherwise you can go back to',
        p4: 'home page',
      },
    },
    download_page: {
      errors: [
        'Only logged in users can download this file.',
        'Invalid Download Token!',
      ],
      preparing: 'Preparing application...',
    },
    user_settings_page: {
      tabs: ['Profile', 'My Apps', 'Security'],
      profile_picture_button_placeholder: 'Pick a picture',
      profile_picture: 'Profile picture',
      profile_picture_description:
        'Your profile picture will appear around Motion Routes website and applications where you contribute or are mentioned.',
      profile: 'Profile details',
      profile_description:
        'Your email address and your username are your identity in Motion Routes and are used to login.',
      name: 'Name:',
      email: 'Email address:',
      current_password_label: 'Your current password',
      username: 'Username:',
      password: 'Password',
      password_description:
        'Changing your password will require you to login in each of your Motion Routes apps.',
      current_password: 'Current Password:',
      repeat_current_password: 'Repeat Password:',
      new_password: 'New Password:',
      update_btn: 'Update Profile',
      empty_apps_error:
        'Sorry! You do not have any purchased applications in your account.',
      empty_apps_error_action: 'Shop Now',
      stripe_disclaimer: [
        'We are currently ',
        'partnered with Stripe ',
        'to manage your Billing details securely.',
      ],
      stripe_disclaimer_action: 'Learn More',
    },
    app_card: {
      linked: 'Linked Machines:',
      author: 'Author:',
      lic: 'License key:',
      reveal_lic_btn: 'Reveal License key',
      current_plan: 'Current Plan:',
      purchase_date: 'Purchase Date:',
      status: 'Status:',
      active: 'Active',
      inactive: 'Inactive',
      billing: 'Billing:',
      auto: 'Auto',
      cancel: 'Cancels',
      download_btn: 'Download',
      manage: 'Manage Subscription',
    },
  });
};
