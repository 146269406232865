// import { cbrt } from "core-js/fn/math";

const helpers = {
  name: 'Test',
  products: {
    free: '44fcc3ea-570d-448f-a510-f5baf9851dec', //0
    advanced: '64c061ba-ef4b-430b-9ffc-515403daf094', //1
    premium: '054cf09d-9041-4a3f-b7a1-c97b6336f4a6', //2
  },
  product: [
    '44fcc3ea-570d-448f-a510-f5baf9851dec',
    '64c061ba-ef4b-430b-9ffc-515403daf094',
    '054cf09d-9041-4a3f-b7a1-c97b6336f4a6',
  ],
  userFaq: [
    {
      question: 'How to install an Adobe HTML Extension',
      answer: `You should extract the ZXP file into the following directory E:/Downloads/[FreeCourseSite.com] Udemy - Nuxt.js - Vue.js on Steroids/5. Connecting our App to the Backend`,
    },
    {
      question: 'How to install an Adobe HTML Extension',
      answer: `You should extract the ZXP file into the following directory E:/Downloads/[FreeCourseSite.com] Udemy - Nuxt.js - Vue.js on Steroids/5. Connecting our App to the Backend`,
    },
    {
      question: 'How to install an Adobe HTML Extension',
      answer: `You should extract the ZXP file into the following directory E:/Downloads/[FreeCourseSite.com] Udemy - Nuxt.js - Vue.js on Steroids/5. Connecting our App to the Backend`,
    },
    {
      question: 'How to install an Adobe HTML Extension',
      answer: `You should extract the ZXP file into the following directory E:/Downloads/[FreeCourseSite.com] Udemy - Nuxt.js - Vue.js on Steroids/5. Connecting our App to the Backend`,
    },
    {
      question: 'How to install an Adobe HTML Extension',
      answer: `You should extract the ZXP file into the following directory E:/Downloads/[FreeCourseSite.com] Udemy - Nuxt.js - Vue.js on Steroids/5. Connecting our App to the Backend`,
    },
  ],
  emailRules: [
    (v) => !!v || 'common.errors[0]',
    (v) => /.+@.+\..+/.test(v) || 'common.errors[1]',
  ],
  userNameRules: [
    (v) => !!v || 'Username is required',
    (v) => (v && v.length <= 12) || 'Username must be less than 12 characters',
  ],
  signupPasswordRules: [
    (v) => !!v || 'Password is required',
    (v) =>
      (v && v.length >= 8) || 'Password must be at least 8 characters long',
  ],
  issueRules: [
    (v) => !!v || 'Issue title is required',
    (v) =>
      (v && v.length <= 30) || 'Issue title must be less than 30 characters',
  ],
  nameRules: [
    (v) => !!v || 'Name is required',
    (v) => (v && v.length <= 20) || 'Name must be less than 20 characters',
  ],
  messageRules: [
    (v) => !!v || 'Message is required',
    (v) => (v && v.length <= 500) || 'Message must be less than 500 characters',
  ],
  loginPasswordRules: [(v) => !!v || 'Password is required'],
  handle(promise) {
    return promise
      .then((data) => [data, undefined])
      .catch((error) => Promise.resolve([undefined, error]));
  },
  async recaptchaScan(context, options) {
    try {
      const token = await context.$recaptcha.execute(options.action);
      const { success, score } = await context.$axios.$post(
        `/recaptcha/${token}`
      );

      [context.success, context.score] = [success, score];
    } catch (error) {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [context.$t(options.error), 'error', true, false];
    } finally {
      options.onError.apply(context);
    }
  },
  async action(context, options) {
    if (!context.$v.$invalid) {
      if (context.success && context.score >= 0.2) {
        context.loader = true;
        const [result, error] = await context.$store.dispatch(
          options.store.action,
          options.store.data
        );
        if (error) {
          [
            context.messageText,
            context.messageType,
            context.showMessage,
            context.loader,
          ] = [error.message, 'error', true, false];
        } else {
          if (options.showFeedback) {
            [
              context.messageText,
              context.messageType,
              context.showMessage,
              context.loader,
            ] = [result, 'success', true, false];
          }
          options.onResolve.apply(context);
        }
      } else {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [context.$t('login.messages[0]'), 'error', true, false];
      }
    } else {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [context.$t('login.messages[2]'), 'error', true, false];
    }
  },
  async performAction(context, options) {
    if (context.success && context.score >= 0.2) {
      context.loader = true;
      const [result, error] = await context.$store.dispatch(
        options.store.action,
        options.store.data
      );
      if (error) {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [error.message, 'error', true, false];
      } else {
        if (options.showFeedback) {
          [
            context.messageText,
            context.messageType,
            context.showMessage,
            context.loader,
          ] = [result, 'success', true, false];
        }
        options.onResolve.apply(context);
      }
    } else {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [context.$t('login.messages[0]'), 'error', true, false];
    }
  },
  async actionNoRecaptcha(context, options) {
    if (!context.$v.$invalid) {
      context.loader = true;
      const [result, error] = await context.$store.dispatch(
        options.store.action,
        options.store.data
      );
      if (error) {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [error.message, 'error', true, false];
      } else {
        if (options.showFeedback) {
          [
            context.messageText,
            context.messageType,
            context.showMessage,
            context.loader,
          ] = [result, 'success', true, false];
        }
        options.onResolve.apply(context);
      }
    } else {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [context.$t('login.messages[2]'), 'error', true, false];
    }
  },
  async actionNoRecaptchaNoInvalid(context, options) {
    context.loader = true;
    const [result, error] = await context.$store.dispatch(
      options.store.action,
      options.store.data
    );
    if (error) {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [error.message, 'error', true, false];
    } else {
      if (options.showFeedback) {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [result, 'success', true, false];
      }
      options.onResolve.apply(context);
    }
  },
  async actionNoRecaptchaWithCustomError(context, options) {
    if (!context.$v.$invalid) {
      context.loader = true;
      const [result, error] = await context.$store.dispatch(
        options.store.action,
        options.store.data
      );
      if (error) {
        if (error.status == options.errorStatusCode) {
          options.onError.apply(context, [error]);
        } else {
          [
            context.messageText,
            context.messageType,
            context.showMessage,
            context.loader,
          ] = [error.message, 'error', true, false];
        }
      } else {
        if (options.showFeedback) {
          [
            context.messageText,
            context.messageType,
            context.showMessage,
            context.loader,
          ] = [result, 'success', true, false];
        }
        options.onResolve.apply(context, [result]);
      }
    } else {
      [
        context.messageText,
        context.messageType,
        context.showMessage,
        context.loader,
      ] = [context.$t('login.messages[2]'), 'error', true, false];
    }
  },
  async actionNoRecaptchaNoInvalidWithCustomError(context, options) {
    context.loader = true;
    const [result, error] = await context.$store.dispatch(
      options.store.action,
      options.store.data
    );
    if (error) {
      if (error.status == options.errorStatusCode) {
        options.onError.apply(context, [error]);
      } else {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [error.message, 'error', true, false];
      }
    } else {
      if (options.showFeedback) {
        [
          context.messageText,
          context.messageType,
          context.showMessage,
          context.loader,
        ] = [result, 'success', true, false];
      }
      options.onResolve.apply(context, [result]);
    }
  },
  async stripeSession(context, options) {
    setTimeout(async () => {
      const [data, error] = await context.$helpers.handle(
        context.$mrApi.$post('/stripe/session/' + options.product)
      );
      if (error) {
        [context.messageText, context.messageType, context.showMessage] = [
          error.response.data,
          'error',
          true,
        ];
      }
      if (data) {
        return context.$stripe.redirectToCheckout({ sessionId: data.id });
      }
    }, options.timer);
  },
  checkRepeatedPassword(context) {
    if (context.password === context.repeatPassword)
      context.repeatPasswordSuccess = context.$t(
        'reset_password.repeat_password_success'
      );
  },
  checkpasswordStrength(context) {
    context.showStrengthMeter = true;
    const color = ['red', 'customTwo', 'orange', 'yellow', 'success'];
    const strength = [0, 25, 50, 75, 100];
    let score = context.$zxcvbn(context.password).score;
    context.strengthColor = color[score];
    context.strength = strength[score];
    if (score == 4)
      context.passwordSuccess = context.$t('signup.password_hint');
  },
  formatDate(rawDate) {
    return new Date(rawDate).toLocaleDateString(
      {},
      { timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' }
    );
  },
  showUIMessage: (txt, type) => [txt, type, true],
};

export default ({ app }, inject) => {
  inject('helpers', helpers);
};
