const fnCore = {
  name: 'Core Helper Functions',
  handle(promise) {
    return promise
      .then((data) => [data, undefined])
      .catch((error) => Promise.resolve([undefined, error]));
  },
  async storeDispatch(context, action, data = null) {
    return context.$store.dispatch(action, data);
  },
  /**
   * Alert the user with an AlertBox message.
   * @param {object} context - Instance context (this)
   * @param {object} options - Alert options
   * @param {string} options.message - Message to display
   * @param {string} options.type - Type of alert (default is 'success')
   * @param {boolean} options.show - Show the alert (default is true)
   * @param {boolean} options.loading - Show the loading UI (default is false)
   */
  alertBox(context, options = {}) {
    [
      context.messageText,
      context.messageType,
      context.showMessage,
      context.loader,
    ] = [
      options.message,
      options.type || 'success',
      options.show || true,
      options.loading || false,
    ];
  },
  alertToast(context, options = {}) {
    context.$notifier.showMessage({
      content: options.message,
      color: options.type || 'success',
      timeout: options.timeout || 3000,
    });
  },
  toggleLoader(context, on) {
    context.loader = on || false;
  },
  isToken(token) {
    return (
      $nuxt.$route.query.token.length % 8 === 0 &&
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
        token
      )
    );
  },
  isProduct(context, product) {
    return $nuxt.$route.query.p === context.$config[product];
  },
};

export default (context, inject) => {
  inject('fnCore', fnCore);
};
