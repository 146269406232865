export default async function ({
  store,
  redirect,
  $helpers,
  $notifier,
  $router,
  app,
}) {
  let selectedProduct = store.state.user.selectedProduct;
  if (!selectedProduct) {
    //TODO: TRANSLAE HERE
    $notifier.showMessage({
      // content: app.i18n.t("signup.email_not_verified"),
      content:
        'You did not select any product! Please select one of the following products!',
      color: 'error',
      timeout: 5000,
    });
    return app.router.push(app.localeRoute('/apps/expression-base/pricing'));
  }
}
