/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import ga from '@/plugins/glossary/gaGlossary';
import rg from '@/plugins/glossary/routesGlossary';
import pg from '@/plugins/glossary/pagesGlossary';

const fn = {
  name: 'Helper Functions',
  log(msg) {
    if (process.env.NODE_ENV === 'development')
      // eslint-disable-next-line no-console
      console.log(`MODE: ${process.env.NODE_ENV}| DEBUG: =>`, msg);
  },
  err(msg) {
    if (process.env.NODE_ENV === 'development')
      // eslint-disable-next-line no-console
      console.error(`ERROR: =>`, msg);
  },
  warn(msg) {
    if (process.env.NODE_ENV === 'development')
      // eslint-disable-next-line no-console
      console.warn(`WARNING: =>`, msg);
  },
  toggleOn(context, variable) {
    context[variable] = true;
  },
  toggleOff(context, variable) {
    context[variable] = false;
  },
  afterLoginRedirect(context) {
    // eslint-disable-next-line no-undef
    const returnUrl = decodeURI($nuxt.$route.query.inc);
    if (returnUrl !== 'undefined')
      context.$router.push({ path: context.localePath(returnUrl) });
    else context.$router.push({ path: context.localePath(pg.home.route) });
  },
  redirect(context, page, options = {}) {
    if (page in pg) {
      if (options.by && options.by === 'free')
        context.$router.push(context.localeRoute(`${pg.download.route}`));
      else if (options.by && options.by === 'paid')
        context.$router.push(context.localeRoute(`${pg.checkout.route}`));
      else if (page === 'login' && options.incomingLink)
        context.$router.push(
          context.localeRoute(
            `${pg[page].route}/?inc=${pg[options.incomingLink].route}`
          )
        );
      else if (options.params) {
        let query = '';
        for (let key in options.params) {
          query += `${key}=${options.params[key]}&`;
        }
        context.$router.push(context.localeRoute(`${pg[page].route}?${query}`));
      } else context.$router.push(context.localeRoute(pg[page].route));
      this.log(`Redirecting to ${page}`);
    }
  },
  isInvalidForm(context, formValidation) {
    if (formValidation) {
      if (context.$v.$invalid) {
        throw new Error(context.$t('login.messages[2]'));
      }
    }
  },
  isInvalidRecaptcha(context, recaptchaValidation) {
    if (recaptchaValidation) {
      if (!context.success && context.score <= 0.2) {
        this.toggleAlert(context, 'box', {
          message: context.$t('login.messages[0]'),
          type: 'error',
        });
        this.toggleOff(context, 'loader');
      }
    }
  },
  getURLParamValue(param) {
    return $nuxt.$route.query[param];
  },
  getPlanTypeByProduct(context, product, plan) {
    if (product in context.$prg) {
      return context.$prg[product].find(({ uuid }) => uuid === plan).type;
    }
    throw new Error(`Product ${product} does not exist!`);
  },
  getPlanPropByProduct(context, options) {
    if (options.product in context.$prg) {
      return context.$prg[options.product].find(
        ({ uuid }) => uuid === options.plan
      )[options.property];
    }
    throw new Error(`Product ${options.product} does not exist!`);
  },
  getProductByPlan(context, planUUID) {
    let product;
    Object.keys(context.$prg).forEach((key) => {
      if (context.$prg[key].find(({ uuid }) => uuid === planUUID))
        product = key;
    });
    return product;
  },
  getProductObjectByPlan(context, planUUID) {
    let product;
    Object.keys(context.$prg).forEach((key) => {
      if (context.$prg[key].find(({ uuid }) => uuid === planUUID))
        product = context.$prg[key].find(({ uuid }) => uuid === planUUID);
    });
    return product;
  },

  // eslint-disable-next-line consistent-return
  verifyURLParams(context, param, options = {}) {
    if ($nuxt.$route.query[param]) {
      if (param === 'token')
        return context.$fnCore.isToken($nuxt.$route.query[param]);
      if (param === 'p')
        return context.$fnCore.isProduct(context, options.product);
    }
  },
  toggleAlert(context, alert, options = {}) {
    if (alert) {
      if (alert === 'box') context.$fnCore.alertBox(context, options);
      else if (alert === 'toast') context.$fnCore.alertToast(context, options);
      this.log('Toggling alert');
    }
  },
  toggleLoader(context, loader, on) {
    if (loader) {
      if (on) this.toggleOn(context, loader);
      else this.toggleOff(context, loader);
    }
    // if (loader) context.loader = on || false;
  },
  onCall(context, func, payload) {
    if (func) func.apply(context, [payload]);
  },
  updateScore(context, options = {}) {
    [context.success, context.score] = [options.success, options.score];
    this.log(`Update score: ${options.score}`);
    this.log(`Update success: ${options.success}`);
  },
  async callMainAPI(context, routeId, payload, options = {}) {
    let constructedRoute;
    if (rg[routeId].verb === 'GET') {
      if (options.params)
        constructedRoute = context.$mrApi.$get(
          rg[routeId].route + options.params
        );
      else constructedRoute = context.$mrApi.$get(rg[routeId].route);
    } else if (rg[routeId].verb === 'POST')
      if (options.params)
        constructedRoute = context.$mrApi.$post(
          rg[routeId].route + options.params,
          payload,
          options.headers
        );
      else
        constructedRoute = context.$mrApi.$post(
          rg[routeId].route,
          payload,
          options.headers
        );
    else if (rg[routeId].verb === 'PUT')
      constructedRoute = context.$mrApi.$put(rg[routeId].route, payload);
    const [data, dataError] = await context.$fnCore.handle(constructedRoute);
    if (dataError) return [undefined, dataError.response.data.error];
    this.onCall(context, options.onResolve, data.message);
    if (options.onlyData) return [data, undefined];
    return [data.message, undefined];
  },
  async callAuthAPI(context, routeId, payload) {
    let result;
    if (rg[routeId].verb === 'GET') {
      if (payload)
        result = await context.$axios.$get(rg[routeId].route + payload);
      else result = await context.$axios.$get(rg[routeId].route);
    } else if (rg[routeId].verb === 'POST')
      result = await context.$axios.$post(rg[routeId].route, payload);
    return result;
  },
  /**
   * Execute a whole set of actions in one page.
   * @param {object} context  - Instance context (this)
   * @param {object} options - Function options
   */
  async exec(context, options) {
    try {
      this.isInvalidForm(context, options.formValidation);
      this.isInvalidRecaptcha(context, options.recaptchaValidation);
      this.toggleLoader(context, options.loader, true);
      if (options.store) {
        const [result, error] = await context.$fnCore.storeDispatch(
          context,
          options.store.action,
          options.store.data
        );
        if (error) {
          if (!options.alertErrorSkip) {
            this.toggleAlert(context, options.alertWith, {
              message: options.alertError || error.message,
              type: 'error',
            });
          }
          this.toggleLoader(context, options.loader);
          this.redirect(context, options.redirect);
          this.onCall(context, options.onError, error);
        } else {
          if (!options.alertSuccessSkip) {
            this.toggleAlert(context, options.alertWith, {
              message: options.alertSuccess || result,
              type: options.alertSuccessType || 'success',
            });
          }
          this.toggleLoader(context, options.loader);
          this.redirect(context, options.redirect);
          this.onCall(context, options.onResolve, result);
        }
      }
    } catch (error) {
      this.toggleAlert(context, options.alertWith, {
        message: error.message,
        type: 'error',
      });
      this.toggleOff(context, 'loader');
    }
  },
  async recaptchaScan(context, options) {
    try {
      const token = await context.$recaptcha.execute(options.action);
      const { success, score } = await this.callAuthAPI(
        context,
        'checkRecaptcha',
        token
      );
      this.updateScore(context, {
        success,
        score,
      });
    } catch (error) {
      context.$fn.toggleOff(context, 'loader');
      context.$fn.toggleAlert(context, 'toast', {
        message: context.$t(options.error),
        type: 'error',
      });
    }
  },
  async checkValidity(context, checkType, isReversed) {
    if (checkType === 'email') {
      this.toggleOn(this, 'emailLoading');
      const result = await this.callAuthAPI(
        context,
        'checkEmail',
        context.email
      );
      if (result === 'ok') {
        context.eMerrors = isReversed
          ? context.$t('forget_password.email_not_available')
          : null;
        context.eMsuccess = isReversed
          ? null
          : context.$t('signup.email_available');
        if (!isReversed) context.$emit('emailFieldUpdated', context.email);
      } else if (result === '!ok') {
        context.eMsuccess = isReversed
          ? context.$t('forget_password.email_available')
          : null;
        context.eMerrors = isReversed
          ? null
          : context.$t('signup.email_not_available');
        if (isReversed) context.$emit('emailFieldUpdated', context.email);
      }
      this.toggleOff(context, 'emailLoading');
    } else if (checkType === 'username') {
      this.toggleOn(this, 'usernameLoading');
      const result = await this.callAuthAPI(
        context,
        'checkUsername',
        context.userName
      );
      if (result === 'ok') {
        context.uNerrors = null;
        context.uNsuccess = context.$t('signup.username_available');
        context.$emit('usernameFieldUpdated', context.userName);
      } else if (result === '!ok') {
        context.uNsuccess = null;
        context.uNerrors = context.$t('signup.username_not_available');
      }
      this.toggleOff(context, 'usernameLoading');
    }
  },
};

export default (context, inject) => {
  inject('fn', fn);
};
